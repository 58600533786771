import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {Spinner} from '~/assets/Spinner.tsx'
import {InfoModalRagRetrievalCount} from '~/components/InfoButtonAndModal.tsx'
import {Select} from '~/components/Select.tsx'
import {type DataSet} from '~/model.ts'
import {useDataSet, useReferenceData, useUpdateDataSet} from '~/services/api.ts'
import {numbersToSelectOptions} from '~/util.ts'

export const DataSetDetails = () => {
	const {dataSetId} = useParams()
	const {data: referenceData} = useReferenceData()
	const {mutate: updateDataSetApi, isPending: updateDataSetIsPending} = useUpdateDataSet()
	const {data: dataSet} = useDataSet(dataSetId)

	const isViewer = dataSet?.role === 'VIEWER'

	const {
		register,
		watch,
		control,
		getValues,
		reset,
		formState: {isValid},
	} = useForm<DataSet>({
		defaultValues: dataSet,
		shouldFocusError: false,
		mode: 'all',
		disabled: isViewer,
	})

	useEffect(() => {
		if (dataSet != null) {
			reset(dataSet)
		}
	}, [dataSet, reset])

	const update = () => {
		updateDataSetApi(getValues())
	}

	return (
		<>
			<div className="w-full grow overflow-y-auto px-[84px] py-[24px]">
				<h1 className="font-lead text-2xl font-medium leading-8">Details & configuration</h1>
				<p className="mt-[8px] max-w-content text-base font-normal leading-6">Customise your data set details and default query configuration.</p>

				<div className="mt-[24px] grid w-full max-w-content grid-cols-1 gap-[24px] rounded-[8px] border border-uom-grey-light-100 p-[24px]">
					<div className="">
						<div className="pb-[4px] font-medium">Data classification</div>
						<div className="">{dataSet?.data_classification}</div>
					</div>
				</div>

				<h3 className="mt-[48px] text-lg font-medium leading-6">Default query configuration</h3>
				<p className="mt-[8px] max-w-content">Query configurations give you control over the depth and relevance of the information retrieved by the AI model.</p>

				<form>
					{!isViewer ? (
						<Select
							options={numbersToSelectOptions(referenceData?.rag_retrieval_count)}
							name="query_configuration.size"
							control={control}
						>
							<div className="mt-[24px] flex max-w-form-sm items-center justify-between font-[600]">
								<label className="block grow">RAG retrieval count</label>
								<InfoModalRagRetrievalCount />
							</div>
						</Select>
					) : (
						<>
							<div className="mt-[24px] flex max-w-form-sm items-center justify-between font-[600]">
								<label className="block grow">RAG retrieval count</label>
								<InfoModalRagRetrievalCount />
							</div>
							<div className="mt-[4px]">{dataSet.query_configuration.size}</div>
						</>
					)}

					<label
						htmlFor="owner"
						className="mt-[34px] block font-[600]"
					>
						Business owner
					</label>
					{!isViewer ? (
						<>
							<input
								className="mt-[4px] w-full max-w-content rounded-[6px] border-uom-grey-light-100 bg-uom-heritage-100 focus:border-uom-yellow-100 focus:ring-uom-yellow-100 disabled:opacity-70"
								placeholder={'E.g. Zoe Visser'}
								{...register('owner')}
								maxLength={30}
							/>
							<div className="mt-[4px]">{watch('owner').length}/30</div>
						</>
					) : (
						<div className="mt-[4px]">{dataSet.owner}</div>
					)}

					<label
						htmlFor="description"
						className="mt-[34px] block font-[600]"
					>
						Description
					</label>
					{!isViewer ? (
						<>
							<textarea
								className="mt-[4px] w-full max-w-content rounded-[6px] border-uom-grey-light-100 bg-uom-heritage-100 focus:border-uom-yellow-100 focus:ring-uom-yellow-100 disabled:opacity-70"
								rows={6}
								{...register('description')}
								maxLength={200}
							/>
							<div className="mt-[4px]">{watch('description').length}/200</div>
						</>
					) : (
						<div className="mt-[4px]">{dataSet.description}</div>
					)}

					{!isViewer && (
						<button
							type="button"
							onClick={update}
							disabled={updateDataSetIsPending || !isValid}
							className="mt-[24px] flex items-center rounded-[4px] border border-gray-300 bg-white px-[11px] py-[7px] text-gray-700 hover:bg-gray-200 disabled:cursor-not-allowed disabled:border-[#4a505d] disabled:bg-[#585d67] disabled:text-[#1c2434]"
						>
							{updateDataSetIsPending ? (
								<>
									<Spinner />
									<span>Updating...</span>
								</>
							) : (
								'Update'
							)}
						</button>
					)}
				</form>
			</div>
		</>
	)
}
