import {Menu} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid'
import {CheckMark} from '~/assets/CheckMark.tsx'

interface Props {
	threadId?: string
	isPlayground: boolean
	showSources?: boolean
	onShowSources?: () => void
	onShowThreadConfig: () => void
	onRenameThread: () => void
	onDeleteThread: () => void
}

export function ThreadMenu(props: Props) {
	return (
		<Menu
			as="div"
			className="absolute right-[64px] top-[30px]"
		>
			<Menu.Button className="rounded-full border border-white p-[2px] text-white">
				<EllipsisVerticalIcon className="h-[24px]" />
			</Menu.Button>
			<Menu.Items className="absolute right-0 top-[24px] z-10 mt-2 w-[234px] rounded-[6px] bg-uom-heritage-75 py-1 text-sm font-normal leading-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
				<Menu.Item>
					<button
						className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
						onClick={props.onShowThreadConfig}
					>
						Thread configuration
					</button>
				</Menu.Item>
				{!props.isPlayground && (
					<Menu.Item>
						<button
							onClick={props.onShowSources}
							className="flex w-full justify-between px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
						>
							<div className="w-full text-left">Show source in thread</div>
							<div className="flex h-[16px] w-[16px] items-center justify-center rounded-[6px] bg-white">{props.showSources && <CheckMark className="h-[12px] w-[12px] text-uom-grey-dark-100" />}</div>
						</button>
					</Menu.Item>
				)}
				{props.threadId != null && (
					<>
						<Menu.Item>
							<button
								className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
								onClick={props.onRenameThread}
							>
								Rename thread
							</button>
						</Menu.Item>
						<Menu.Item>
							<button
								onClick={props.onDeleteThread}
								className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
							>
								Delete thread
							</button>
						</Menu.Item>
					</>
				)}
			</Menu.Items>
		</Menu>
	)
}
