export const SparkIcon = ({className}: {className: string}) => (
	<svg
		className={className}
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.3378 21.0594C19.7901 21.5516 18.9471 21.5066 18.4549 20.9589C17.2376 19.6043 16.2299 18.4476 15.259 17.3331C13.875 15.7444 12.5659 14.2416 10.8313 12.3743C9.70781 11.1649 8.56723 10.0098 7.41108 8.83887C6.72523 8.14428 6.0339 7.44414 5.33742 6.72382C4.82555 6.19443 4.83975 5.35033 5.36913 4.83846C5.89852 4.3266 6.74262 4.3408 7.25448 4.87018C7.90857 5.54665 8.58202 6.22888 9.26135 6.91707C10.4418 8.11288 11.6399 9.32666 12.7851 10.5594C14.5302 12.4381 15.9008 14.0105 17.3192 15.6377C18.2912 16.7529 19.2856 17.8937 20.4383 19.1765C20.9305 19.7242 20.8855 20.5672 20.3378 21.0594Z"
			fill="#000F46"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.9019 12.6885C21.9471 13.4235 21.3878 14.0559 20.6528 14.101C18.7982 14.2149 17.138 14.2614 15.5817 14.305C11.2378 14.4267 7.70322 14.5257 3.01014 16.0028C2.30773 16.2239 1.5591 15.8337 1.33802 15.1313C1.11694 14.4289 1.50713 13.6803 2.20954 13.4592C7.28449 11.8619 11.2916 11.7507 15.7531 11.6269C17.2605 11.5851 18.8197 11.5419 20.4894 11.4394C21.2244 11.3943 21.8568 11.9535 21.9019 12.6885Z"
			fill="#000F46"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.3621 2.99765C17.0686 3.20544 17.4728 3.94659 17.265 4.65304C16.6178 6.85353 15.9301 9.04718 15.244 11.2357C14.1972 14.5751 13.1541 17.9025 12.264 21.2243C12.0734 21.9356 11.3423 22.3577 10.631 22.1671C9.91974 21.9765 9.49763 21.2454 9.68822 20.5341C10.5864 17.182 11.6667 13.7333 12.7309 10.3361C13.4136 8.15662 14.0897 5.99838 14.7067 3.90056C14.9145 3.1941 15.6557 2.78986 16.3621 2.99765Z"
			fill="#000F46"
		/>
	</svg>
)
