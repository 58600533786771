import {useParams} from 'react-router-dom'
import {InfoCircleIcon} from '~/assets/InfoCircleIcon.tsx'
import {Draw} from '~/components/Draw.tsx'
import {LoadingAnimation} from '~/components/LoadingAnimation.tsx'
import {type UIMessage} from '~/model.ts'
import {useMessage} from '~/services/api.ts'
import {isValidHttpUrl} from '~/util.ts'

interface Props {
	message?: UIMessage
	onDone: () => void
}

export const MessageRagSources = ({message, onDone}: Props) => {
	const {projectId, threadId} = useParams()

	const hasSources = message?.sources != null && message.sources.length > 0

	const {data: messageDetails, isLoading} = useMessage(projectId, threadId, hasSources ? message.id : undefined)

	const max_score = messageDetails?.retrievalResults.reduce((max, result) => Math.max(max, result.score), 0)

	return (
		<Draw
			open={message != null}
			onDone={onDone}
		>
			<div className="overflow-y-auto px-[120px] py-[24px] text-sm font-normal leading-5 text-white">
				<div className="max-w-content">
					<h2 className="font-lead text-2xl font-medium leading-8">RAG sources</h2>
					<p className="mt-1 text-base font-normal leading-6">Retrieval augmented generation (RAG) sources show you which chunks of your data set were referenced by the LLM to give the response. </p>
				</div>
				{isLoading && (
					<div className="flex items-center justify-center">
						<LoadingAnimation />
					</div>
				)}

				<div className="mt-[24px] grid w-full grid-cols-1 gap-[24px] rounded-[8px] border border-gray-400 p-[24px] min-[848px]:grid-cols-2 min-[1112px]:grid-cols-3">
					<div className="">
						<div className="pb-[4px] font-medium">Thread ID</div>
						<div className="text-uom-grey-light-50">{threadId}</div>
					</div>
					<div className="">
						<div className="pb-[4px] font-medium">Response ID</div>
						<div className="text-uom-grey-light-50">{message?.id}</div>
					</div>
					<div className="">
						<div className="pb-[4px] font-medium">Max Relevance Score</div>
						<div className="text-uom-grey-light-50">{max_score}</div>
					</div>
				</div>

				{messageDetails != null && (
					<>
						<div className="mt-[24px] flex max-w-content flex-col gap-y-[24px]">
							<div>{messageDetails.retrievalResults.length} chunks</div>
							{messageDetails.retrievalResults.map((result) => (
								<div
									key={result.location.s3Location.uri}
									className="flex flex-col gap-y-[12px]"
								>
									{/*<div className="max-w-max rounded-[4px] bg-uom-blue-light-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-uom-heritage-100">{result.content.text}</div>*/}
									<div>
										<div className="break-all text-base font-semibold leading-6">{result.location.s3Location.uri.split('/').pop()}</div>
										<div className="flex items-start">
											<div className="text-semibold pr-[8px]">Source:</div>
											{isValidHttpUrl(result.location.s3Location.uri) ? (
												<a
													href={result.location.s3Location.uri}
													className="break-all underline"
												>
													{result.location.s3Location.uri}
												</a>
											) : (
												<div className="break-all">{result.location.s3Location.uri}</div>
											)}
										</div>
									</div>

									<div className="max-w-max flex-shrink-0 rounded-[4px] bg-uom-blue-light-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-uom-heritage-100">{`Relevance score: ${result.score}`}</div>
									<div className="flex max-w-max items-start gap-x-[4px] rounded-[4px] bg-uom-yellow-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-uom-heritage-100">
										<div className="flex-shrink-0">URI:</div>
										<div className="grow break-all">{result.location.s3Location.uri}</div>
									</div>

									<div>{result.content.text}</div>
								</div>
							))}
						</div>
					</>
				)}

				{!hasSources && (
					<div className="mt-[24px] flex max-w-content flex-col gap-y-[24px]">
						<div>0 chunks</div>
						<div className="flex items-center gap-x-[12px] rounded-r-[6px] border-l-[4px] border-uom-blue-light-100 bg-uom-heritage-100 p-[16px] text-uom-blue-light-100">
							<InfoCircleIcon className="h-[20px] shrink-0" />
							<div>The LLM didn't reference any RAG sources for this response.</div>
						</div>
					</div>
				)}
			</div>
		</Draw>
	)
}
