export const CloseIcon = ({className}: {className?: string}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 8 8"
		fill="none"
		className={className}
	>
		<g clipPath="url(#clip0_3992_27648)">
			<path
				d="M7 1L1 7M1 1L7 7L1 1Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3992_27648">
				<rect
					width="8"
					height="8"
					fill="white"
				/>
			</clipPath>
		</defs>
	</svg>
)
