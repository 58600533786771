import {format} from 'date-fns'
import {SelectOption} from '~/components/Select.tsx'

export function isValidHttpUrl(input: string) {
	let url

	try {
		url = new URL(input)
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
	} catch (_) {
		return false
	}

	return url.protocol === 'http:' || url.protocol === 'https:'
}

// Convert _ & - to spaces and capitalize the first letter of each word
export const convertToTitleCase = (str?: string) => {
	if (!str) return
	return str
		.replace(/[_-]/g, ' ')
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ')
}

export const formatDate = (date?: string) => {
	return format(new Date(date ?? ''), 'LLL d, yyyy')
}

export function capitalizeFirstChar(str?: string) {
	if (!str || str.length === 0) return ''
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export async function fileToText(file: File) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = (event) => {
			if (event.target?.result && typeof event.target.result === 'string') {
				resolve(event.target.result.toString())
			} else {
				reject(new Error('Failed to read the file.'))
			}
		}
		reader.onerror = (event) => {
			console.error(event)
			reject(new Error('Failed to read the file.'))
		}
		reader.readAsText(file)
	})
}

export function convertBytesNumberToString(bytes: number): string {
	if (bytes === 0) return '0 bytes'

	const k = 1024
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	let i = Math.floor(Math.log(bytes) / Math.log(k))
	if (bytes / Math.pow(k, i) >= 100) {
		i = i + 1
	}

	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)).toString() + sizes[i]
}

export function convertBytesSizeStringToNumber(sizeString: string): number {
	const units = ['B', 'KB', 'MB', 'GB', 'TB']
	const match = /^(\d+(?:\.\d+)?)([KMGT]?B)$/i.exec(sizeString)

	if (match && typeof match[1] === 'string' && typeof match[2] === 'string') {
		const value = parseFloat(match[1])
		const unit = match[2].toUpperCase()
		const exponent = units.indexOf(unit)
		return Math.round(value * Math.pow(1024, exponent))
	}

	return 0
}

export function numbersToSelectOptions(numbers?: number[]): SelectOption[] {
	if (!numbers) return []
	return numbers.map((number) => ({label: number.toString(), value: number}))
}
