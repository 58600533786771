import {useAuth} from 'react-oidc-context'
import {useUserInfo} from '~/services/auth.ts'

export const Settings = () => {
	const auth = useAuth()
	const {userInfo} = useUserInfo()

	return (
		<div className="flex grow">
			<div className="flex w-[240px] flex-col justify-between border-r border-gray-400 bg-uom-heritage-100 px-[12px] py-[24px]">
				<div className="flex grow flex-col gap-y-[24px]">
					<h1 className="font-lead text-lg font-medium leading-6">Settings</h1>
					<div className="flex grow flex-col">
						<div className="group flex items-center justify-between rounded-[6px] border border-white hover:bg-uom-heritage-75">
							<div className="flex-1 overflow-x-hidden overflow-ellipsis whitespace-nowrap py-[8px] pl-[8px] text-left text-sm font-medium leading-5">Profile</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-full px-[84px] py-[24px]">
				<h1 className="font-lead text-2xl font-medium leading-8">Profile</h1>
				<div className="mt-[24px] grid w-full max-w-[984px] grid-cols-3 gap-[24px] rounded-[8px] border border-gray-400 p-[24px] text-sm font-normal leading-5">
					<div>
						<div className="pb-[4px] font-medium">First name</div>
						<div className="text-uom-grey-light-50">{userInfo?.given_name}</div>
					</div>
					<div>
						<div className="pb-[4px] font-medium">Last name</div>
						<div className="text-uom-grey-light-50">{userInfo?.family_name}</div>
					</div>
					<div>
						<div className="pb-[4px] font-medium">Email address</div>
						<div className="text-uom-grey-light-50">{auth.user?.profile.email}</div>
					</div>
				</div>
			</div>
		</div>
	)
}
