import {Dialog, Disclosure, Transition} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon, DocumentDuplicateIcon, ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import {clsx} from 'clsx'
import {Fragment, useEffect, useRef, useState} from 'react'
import {useAuth} from 'react-oidc-context'
import {redirect} from 'react-router-dom'
import {useInterval} from 'usehooks-ts'
import {axiosAuthInstance, queryClient} from '~/services/api.ts'

interface NetworkError {
	config?: {
		method?: string
	}
	request?: {
		responseURL?: string
	}
	response?: {
		status?: number
		headers?: {
			'x-amzn-requestid'?: string
		}
		data?: {
			message?: string
		}
	}
}

export const NetworkErrorHandling = () => {
	const okayButtonRef = useRef(null)

	const [error, setError] = useState<{requestId?: string; url?: string; method?: string; status?: number; message?: string} | null>(null)
	const [copied, setCopied] = useState(false)

	const auth = useAuth()

	useInterval(
		() => {
			setCopied(false)
		},
		copied ? 5000 : null,
	)

	// Create a response interceptor for error handling
	useEffect(() => {
		axiosAuthInstance.interceptors.response.use(
			(response) => {
				return response
			},
			(rejectedError) => {
				const typedError = rejectedError as NetworkError
				if (403 === typedError.response?.status) {
					void auth.removeUser()
					void auth.signinRedirect()
				} else {
					if (error == null && performance.now() > 1000) {
						setError({
							url: typedError.request?.responseURL,
							method: typedError.config?.method,
							requestId: typedError.response?.headers != null ? typedError.response.headers['x-amzn-requestid'] : undefined,
							status: typedError.response?.status,
							message: typedError.response?.data?.message,
						})
					}
					return Promise.reject(new Error('An error occurred while communicating with the server.'))
				}
			},
		)
	})

	const onClose = () => {
		setError(null)
	}

	const onOkay = () => {
		setError(null)
		queryClient.clear()
		localStorage.clear()
		if (location.pathname.startsWith('/securechat')) {
			redirect('/securechat')
		}
		if (location.pathname.startsWith('/projects')) {
			redirect('/projects')
		}
		if (location.pathname.startsWith('/datasets')) {
			redirect('/datasets')
		}
	}

	function copyErrorDetailsToClipboard() {
		setCopied(true)
		if (error == null) {
			return
		}
		const text = `Request Id: ${error.requestId} \nMethod: ${error.method} \nURL: ${error.url} \nStatus: ${error.status} \nMessage: ${error.message}`
		void navigator.clipboard.writeText(text)
	}

	return (
		<>
			{error != null && (
				<Transition.Root
					show={true}
					as={Fragment}
					appear={true}
				>
					<Dialog
						as="div"
						className="relative z-10"
						initialFocus={okayButtonRef}
						onClose={onClose}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative m-8 w-full max-w-[512px] transform overflow-hidden rounded-lg bg-uom-heritage-100 text-left shadow-xl transition-all">
										<div className="px-[24px] pb-[16px] pt-[24px] sm:flex sm:items-start">
											<div className="mx-auto flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-full bg-uom-heritage-75 sm:mx-0">
												<ExclamationTriangleIcon
													className="h-[24px] text-red-600"
													aria-hidden="true"
												/>
											</div>
											<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
												<Dialog.Title
													as="h3"
													className="text-lg font-medium leading-6 text-white"
												>
													{error.status === 408 ? 'Request timed out' : 'Sever error'}
												</Dialog.Title>
												<div className="mt-2 text-sm text-uom-grey-light-50">
													<p className="text-sm">
														{error.status === 408 ? 'The server has timed out.' : 'An error occurred while communicating with the server.'} The application will delete it's local cache and reload the
														page.
													</p>
													{error.requestId != null && (
														<>
															<p className="mt-[1rem]">Please provide the following request id to technical support when you contact them:</p>
															<div className="mt-[.5rem] break-all">{error.requestId}</div>
														</>
													)}
													{error.status != null && (
														<Disclosure>
															{({open}) => (
																<>
																	<Disclosure.Button className="flex items-center pb-2 pt-4 font-semibold">
																		Technical Details <ChevronDownIcon className={clsx(open && 'rotate-180', 'h-[1rem] px-[8px]')} />
																	</Disclosure.Button>
																	<Disclosure.Panel className="text-gray-200">
																		<div className="grid grid-cols-[auto_auto] gap-x-[8px] gap-y-[4px]">
																			<div className="">Method:</div>
																			<div className="uppercase">{error.method}</div>
																			<div className="">URL:</div>
																			<div className="">{error.url}</div>
																			<div className="">Status:</div>
																			<div className="">{error.status}</div>
																			{error.message != null && (
																				<>
																					<div className="">Message:</div>
																					<div className="">{error.message}</div>
																				</>
																			)}
																		</div>
																	</Disclosure.Panel>
																</>
															)}
														</Disclosure>
													)}
												</div>
											</div>
										</div>
										<div className="flex justify-end gap-x-[12px] bg-uom-heritage-75 px-[24px] py-[12px] text-sm font-medium leading-5">
											<button
												type="button"
												className="flex items-center justify-center gap-x-[8px] rounded-md border border-solid border-gray-300 px-[15px] shadow-sm"
												onClick={copyErrorDetailsToClipboard}
											>
												{copied ? (
													<CheckIcon className="aspect-square w-5 max-w-full items-center justify-center self-stretch overflow-hidden object-contain object-center" />
												) : (
													<DocumentDuplicateIcon className="aspect-square w-5 max-w-full items-center justify-center self-stretch overflow-hidden object-contain object-center" />
												)}
												<div className="text-sm font-medium leading-5 text-white">{copied ? 'Copied' : 'Copy technical details'}</div>
											</button>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-uom-grey-dark-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
												onClick={onOkay}
												ref={okayButtonRef}
											>
												Okay
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			)}
		</>
	)
}
