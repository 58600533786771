import {ExclamationTriangleIcon} from '@heroicons/react/24/solid'

export const FileSectionErrors = (props: {fileExtensionError: boolean; fileSizeError: boolean}) => {
	return (
		<div className="mt-[48px] flex flex-col gap-y-[8px]">
			{props.fileExtensionError && (
				<div className="flex max-w-content-sm gap-x-[12px] rounded-r-[6px] border-l-4 border-yellow-400 bg-yellow-50 p-[16px]">
					<ExclamationTriangleIcon className="h-[27px] text-yellow-400" />
					<div className="text-sm font-medium leading-5 text-yellow-800">
						One of more of your files is the incorrect format.{' '}
						<a
							href="#"
							className="underline"
						>
							Read more about accepted file types and best practice.
						</a>
					</div>
				</div>
			)}
			{props.fileSizeError && (
				<div className="flex max-w-content-sm gap-x-[12px] rounded-r-[6px] border-l-4 border-yellow-400 bg-yellow-50 p-[16px]">
					<ExclamationTriangleIcon className="h-[27px] text-yellow-400" />
					<div className="text-sm font-medium leading-5 text-yellow-800">
						The file size/s is too large. The max total file size is 100mb.{' '}
						<a
							href="#"
							className="underline"
						>
							Read more about upload file sizes.
						</a>
					</div>
				</div>
			)}
		</div>
	)
}
