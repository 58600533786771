import {clsx} from 'clsx'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Draw} from '~/components/Draw.tsx'
import {InfoModalRagRetrievalCount} from '~/components/InfoButtonAndModal.tsx'
import {Select} from '~/components/Select.tsx'
import {UniqueNameRequiredModal} from '~/components/UniqueNameRequiredModal.tsx'
import {type DataSet} from '~/model.ts'
import {useDataSets, useReferenceData} from '~/services/api.ts'
import {numbersToSelectOptions} from '~/util.ts'

interface Props {
	open: boolean
	onCancel: () => void
	onCreateDataSet: (dataSet: DataSet) => void
}

export const DataSetCreate = ({open, onCancel, onCreateDataSet}: Props) => {
	const {data: dataSets} = useDataSets()
	const {data: referenceData} = useReferenceData()

	const [uniqueNameRequiredOpen, setUniqueNameRequiredOpen] = useState(false)

	const defaultDataSet = {
		description: '',
		name: '',
		owner: '',
		query_configuration: {
			size: referenceData?.rag_retrieval_default,
		},
		vector_configuration: {
			chunk_type: 'token',
			chunk_size: referenceData?.chunk_size.default,
		},
	}

	const {
		register,
		formState: {isValid, errors},
		reset,
		watch,
		getValues,
		control,
		trigger,
		setFocus,
	} = useForm<DataSet>({
		defaultValues: defaultDataSet,
		shouldFocusError: false,
		mode: 'all',
	})

	useEffect(() => {
		if (referenceData) {
			reset({
				...defaultDataSet,
				query_configuration: {
					size: referenceData.rag_retrieval_default,
				},
				vector_configuration: {
					chunk_type: 'token',
					chunk_size: referenceData.chunk_size.default,
				},
			})
		}
	}, [referenceData, reset])

	useEffect(() => {
		if (open) {
			setTimeout(() => void trigger(), 5000)
		}
	}, [open, trigger])

	useEffect(() => {
		setTimeout(() => {
			setFocus('name')
		}, 500)
	}, [open, setFocus])

	return (
		<Draw
			open={open}
			onCancel={() => {
				reset(defaultDataSet)
				onCancel()
			}}
			onApply={() => {
				const newDataSet = getValues()
				if (
					dataSets?.personalDataSets.some(
						(dataSet) => newDataSet.name.toLowerCase() === dataSet.name.toLowerCase() || dataSets.globalDataSets.some((dataSet) => newDataSet.name.toLowerCase() === dataSet.name.toLowerCase()),
					)
				) {
					setUniqueNameRequiredOpen(true)
					return
				}
				onCreateDataSet(getValues())
				reset(defaultDataSet)
			}}
			applyLabel="Create data set"
			applyDisabled={!isValid}
		>
			<form className="h-full overflow-y-auto">
				<div className="max-w-content py-[24px] pl-[120px] text-sm font-normal leading-5 text-white">
					<h2 className="font-lead text-2xl font-medium leading-8">Create a new data set</h2>
					<p className="mt-1 text-base font-normal leading-6">Data sets are curated information pools. Add details below to create a new data set, you will then be required to upload files.</p>

					<label
						htmlFor="projectName"
						className="mt-[34px] block font-medium"
					>
						* Data set name
					</label>
					<input
						{...register('name', {required: true, minLength: 2})}
						className={clsx(
							errors.name != null
								? '!focus:ring-uom-red-light-100 !focus:border-uom-red-light-100 !focus:outline-none !border-uom-red-light-100 !ring-uom-red-light-100'
								: 'border-uom-grey-light-100 ring-transparent focus:border-uom-yellow-100 focus:ring-uom-yellow-100',
							'border-1 mt-[4px] w-full max-w-form-sm rounded-[6px] bg-uom-heritage-100 ring-1',
						)}
						maxLength={50}
						minLength={2}
						placeholder="E.g. Frank v1.0"
					/>
					{errors.name == null && <div className="mt-[4px]">{watch('name').length}/50</div>}
					{errors.name?.type === 'required' && (
						<p
							role="alert"
							className="mt-[4px] text-uom-red-light-100"
						>
							Please give the data set a name
						</p>
					)}
					{errors.name?.type === 'minLength' && (
						<p
							role="alert"
							className="mt-[4px] text-uom-red-light-100"
						>
							Data set name must be at least 2 characters
						</p>
					)}

					<label
						htmlFor="owner"
						className="mt-[34px] block"
					>
						Business owner
					</label>
					<input
						className="mt-[4px] w-full rounded-[6px] border-uom-grey-light-100 bg-uom-heritage-100 focus:border-uom-yellow-100 focus:ring-uom-yellow-100"
						placeholder={'E.g. Zoe Visser'}
						{...register('owner')}
						maxLength={30}
					/>
					<div className="mt-[4px]">{watch('owner').length}/30</div>

					<label
						htmlFor="description"
						className="mt-[34px] block"
					>
						Description
					</label>
					<textarea
						className="mt-[4px] w-full rounded-[6px] border-uom-grey-light-100 bg-uom-heritage-100 focus:border-uom-yellow-100 focus:ring-uom-yellow-100"
						rows={6}
						{...register('description')}
						maxLength={200}
					/>
					<div className="mt-[4px]">{watch('description').length}/200</div>

					<h3 className="mt-[48px] text-lg font-medium leading-6">Default query configuration</h3>
					<Select
						options={numbersToSelectOptions(referenceData?.rag_retrieval_count)}
						name="query_configuration.size"
						control={control}
					>
						<div className="mt-[24px] flex max-w-form-sm items-center justify-between font-medium">
							<label className="block grow">RAG retrieval count</label>
							<InfoModalRagRetrievalCount />
						</div>
					</Select>
				</div>
			</form>
			<UniqueNameRequiredModal
				itemType="data set"
				open={uniqueNameRequiredOpen}
				onClose={() => {
					setUniqueNameRequiredOpen(false)
				}}
			/>
		</Draw>
	)
}
