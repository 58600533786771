import {Menu} from '@headlessui/react'
import {MoonIcon, SunIcon} from '@heroicons/react/24/solid'
import {clsx} from 'clsx'
import {useAtom} from 'jotai'
import {atomWithStorage} from 'jotai/utils'
import {useAuth} from 'react-oidc-context'
import {Link, NavLink} from 'react-router-dom'
import {useUserInitials} from '~/services/auth.ts'

const darkModeAtom = atomWithStorage('darkMode', document.documentElement.classList.contains('dark'))

export const Navbar = () => {
	const auth = useAuth()
	const userInitials = useUserInitials()

	const [darkMode, setDarkMode] = useAtom(darkModeAtom)
	const toggleDarkMode = () => {
		setDarkMode(!darkMode)
		document.documentElement.classList.toggle('dark')
		localStorage.setItem('darkMode', document.documentElement.classList.contains('dark').toString())
	}

	return (
		<nav className="flex h-[70px] shrink-0 items-center justify-between border-b border-uom-heritage-50 bg-uom-heritage-100 pl-[24px] pr-[24px] md:pr-[120px]">
			<div className="flex flex-grow items-center gap-x-[52px]">
				<Link
					to="/securechat"
					className="flex flex-shrink-0 items-center gap-x-[24px]"
				>
					<img
						alt="University of Melbourne"
						src="/uom-logo.svg"
						className="h-[54px]"
					/>{' '}
					<img
						alt="Spark"
						src="/spark-logo.svg"
						className="mt-[-6px] h-[40px]"
					/>
				</Link>

				<div className="flex space-x-[24px]">
					<NavLink
						to="/securechat"
						className={({isActive}) =>
							clsx(
								isActive ? 'border-white bg-uom-heritage-150' : 'border-transparent',
								'rounded-md border px-[12px] py-[8px] text-sm font-medium leading-5 hover:bg-uom-heritage-75 focus:border-uom-yellow-100 focus:outline-none',
							)
						}
						aria-current="page"
					>
						Secure chat
					</NavLink>
					<NavLink
						to="/projects"
						className={({isActive}) =>
							clsx(
								isActive ? 'border-white bg-uom-heritage-150' : 'border-transparent',
								'rounded-md border px-[12px] py-[8px] text-sm font-medium leading-5 hover:bg-uom-heritage-75 focus:border-uom-yellow-100 focus:outline-none',
							)
						}
						aria-current="page"
					>
						Projects
					</NavLink>
					<NavLink
						to="/datasets"
						className={({isActive}) =>
							clsx(
								isActive ? 'border-white bg-uom-heritage-150' : 'border-transparent',
								'rounded-md border px-[12px] py-[8px] text-sm font-medium leading-5 hover:bg-uom-heritage-75 focus:border-uom-yellow-100 focus:outline-none',
							)
						}
						aria-current="page"
					>
						Data sets
					</NavLink>
				</div>
			</div>
			<div className="flex items-center gap-x-[8px]">
				<button
					onClick={toggleDarkMode}
					className="hidden rounded-full border border-uom-heritage-25 bg-uom-heritage-150 p-[10px] text-sm font-medium leading-5"
				>
					{darkMode ? <SunIcon className="h-[20px] w-[20px] text-uom-yellow-100" /> : <MoonIcon className="h-[20px] w-[20px] text-uom-yellow-100" />}
				</button>
				<Menu
					as="div"
					className="relative"
				>
					<Menu.Button className="rounded-full border border-uom-heritage-25 bg-uom-heritage-150 p-[10px] text-sm font-medium leading-5">
						<div className="flex h-[20px] w-[20px] justify-center">{userInitials ?? ''}</div>
					</Menu.Button>
					<Menu.Items className="absolute right-0 z-10 mt-2 w-[140px] rounded-[6px] bg-uom-heritage-75 py-1 text-sm font-normal leading-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<Menu.Item>
							<Link
								to="/settings"
								className="block px-4 py-2 data-[active]:bg-uom-heritage-100"
							>
								Settings
							</Link>
						</Menu.Item>
						<Menu.Item>
							<button
								onClick={() => {
									void auth.removeUser()
								}}
								className="block px-4 py-2 data-[active]:bg-uom-heritage-100"
							>
								Sign out
							</button>
						</Menu.Item>
					</Menu.Items>
				</Menu>
			</div>
		</nav>
	)
}
