import {Dialog, Transition} from '@headlessui/react'
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import {Fragment, useRef} from 'react'

interface DeleteConfirmationModalProps {
	open: boolean
	itemType: string
	onClose: () => void
	onDelete: () => void
}

export const DeleteConfirmationModal = ({open, itemType, onClose, onDelete}: DeleteConfirmationModalProps) => {
	const cancelButtonRef = useRef(null)

	return (
		<Transition.Root
			show={open}
			as={Fragment}
			appear={true}
		>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={onClose}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative m-8 w-full max-w-[512px] transform overflow-hidden rounded-lg bg-uom-heritage-100 text-left shadow-xl transition-all">
								<div className="px-[24px] pb-[16px] pt-[24px] sm:flex sm:items-start">
									<div className="mx-auto flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-full bg-uom-red-light-100 sm:mx-0">
										<ExclamationTriangleIcon
											className="h-[24px] text-uom-heritage-100"
											aria-hidden="true"
										/>
									</div>
									<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-white"
										>
											Are you sure?
										</Dialog.Title>
										<div className="mt-2 text-uom-grey-light-50">
											<p className="text-sm">Are you sure you want to delete this {itemType}?</p>
											<p className="mt-[1rem] text-sm">This action cannot be undone.</p>
										</div>
									</div>
								</div>
								<div className="bg-uom-heritage-75 px-[24px] py-[12px] text-sm font-medium leading-5 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="inline-flex w-full justify-center rounded-md bg-uom-red-light-100 px-3 py-2 text-white shadow-sm hover:bg-uom-red-light-125 sm:ml-[12px] sm:w-auto"
										onClick={onDelete}
									>
										Delete {itemType}
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-uom-grey-dark-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										onClick={onClose}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
