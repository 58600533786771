import {CheckIcon, PencilIcon} from '@heroicons/react/24/outline'
import {clsx} from 'clsx'
import {useEffect, useState} from 'react'
import {Link, NavLink, Outlet, useLocation, useParams, useSearchParams} from 'react-router-dom'
import {twMerge} from 'tailwind-merge'
import {ArrowNarrowLeftIcon} from '~/assets/ArrowNarrowLeft.tsx'
import {LoadingAnimation} from '~/components/LoadingAnimation.tsx'
import {UniqueNameRequiredModal} from '~/components/UniqueNameRequiredModal.tsx'
import {useDataSet, useDataSets, useUpdateDataSet} from '~/services/api.ts'

export const DataSetView = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const {dataSetId} = useParams()
	const pathname = useLocation().pathname
	const files = pathname.endsWith('/files') || pathname.endsWith('/files/')

	const {data: dataSet, isLoading: dataSetIsLoading} = useDataSet(dataSetId)
	const {data: dataSets} = useDataSets()
	const {mutate: updateDataSetApi} = useUpdateDataSet()

	const [renameDataSetValue, setRenameDataSetValue] = useState<string | null>(null)
	const [uniqueNameRequiredOpen, setUniqueNameRequiredOpen] = useState(false)

	useEffect(() => {
		if (dataSet != null && searchParams.has('rename')) {
			setRenameDataSetValue(dataSet.name)
			searchParams.delete('rename')
			setSearchParams(searchParams)
		}
	}, [searchParams, dataSet, setSearchParams])

	const renameDataSet = () => {
		if (renameDataSetValue == null || dataSet == null || renameDataSetValue.length < 2) return
		if (
			dataSets?.personalDataSets.some((dataSetToCompare) => dataSetToCompare.name.toLowerCase() === renameDataSetValue.toLowerCase() && dataSet.SK != dataSetToCompare.SK) ??
			dataSets?.globalDataSets.some((dataSetToCompare) => dataSetToCompare.name.toLowerCase() === renameDataSetValue.toLowerCase())
		) {
			setUniqueNameRequiredOpen(true)
			return
		}
		updateDataSetApi({...dataSet, name: renameDataSetValue})
		// mutateRenameDataSet({dataSetId: dataSet.SK, newName: renameDataSetValue})
		setRenameDataSetValue(null)
	}

	if (dataSetIsLoading || dataSet == null) {
		return (
			<div className="flex items-center justify-center">
				<LoadingAnimation />
			</div>
		)
	}

	return (
		<>
			<div className="flex grow">
				<div className="flex w-[240px] flex-shrink-0 flex-col justify-between border-r border-gray-400 bg-uom-heritage-100 px-[12px] py-[24px]">
					<div className="flex grow flex-col">
						<Link
							to="/datasets"
							className="flex items-center gap-x-[12px] self-start rounded-[6px] px-[12px] py-[4px] text-sm font-normal leading-5 text-white hover:bg-uom-heritage-75"
						>
							<ArrowNarrowLeftIcon className="h-[16px]" />
							<div>Back</div>
						</Link>
						<h2 className="mt-[12px] flex items-center justify-between text-lg font-medium leading-6">
							{renameDataSetValue == null ? (
								<>
									<div className="max-w-[191px] break-words">{dataSet.name}</div>
									{dataSet.role !== 'VIEWER' && (
										<button
											className="flex h-[24px] w-[24px] items-center justify-center rounded-full hover:bg-uom-heritage-150"
											onClick={() => {
												setRenameDataSetValue(dataSet.name)
											}}
										>
											<PencilIcon className="h-[16px]" />
										</button>
									)}
								</>
							) : (
								<>
									<input
										type="text"
										value={renameDataSetValue}
										onChange={(event) => {
											setRenameDataSetValue(event.target.value)
										}}
										onKeyDown={(event) => {
											if (event.key === 'Enter') renameDataSet()
										}}
										className={twMerge(
											'mt-[-1px] w-full overflow-hidden rounded-[2px] border !border-uom-yellow-100 bg-uom-heritage-100 py-0 pl-[4px] text-lg font-medium leading-6 outline-uom-yellow-100 focus:outline-none focus:ring-0',
											renameDataSetValue.length < 2 && '!border-uom-red-light-100',
										)}
										minLength={2}
										maxLength={50}
									/>
									<button
										onClick={() => {
											renameDataSet()
										}}
										className="ml-[8px] flex h-[24px] w-[24px] items-center justify-center rounded-full hover:bg-gray-800"
									>
										<CheckIcon className="h-[16px]" />
									</button>
								</>
							)}
						</h2>

						<div
							className={clsx(
								!files ? 'border-white' : 'border-transparent',
								'group mt-[24px] flex items-center justify-between rounded-[6px] border hover:bg-uom-heritage-75 focus:border-uom-yellow-100 focus:outline-none',
							)}
						>
							<NavLink
								to={`/datasets/${dataSet.SK}`}
								className="flex-1 overflow-x-hidden overflow-ellipsis whitespace-nowrap py-[8px] pl-[8px] text-left text-sm font-medium leading-5"
							>
								Details & configuration
							</NavLink>
						</div>
						<div
							className={clsx(
								files ? 'border-white' : 'border-transparent',
								'group mt-[4px] flex items-center justify-between rounded-[6px] border hover:bg-uom-heritage-75 focus:border-uom-yellow-100 focus:outline-none',
							)}
						>
							<NavLink
								to={`/datasets/${dataSet.SK}/files`}
								className="flex-1 overflow-x-hidden overflow-ellipsis whitespace-nowrap py-[8px] pl-[8px] text-left text-sm font-medium leading-5"
							>
								Data files
							</NavLink>
						</div>
					</div>
				</div>
				<div className="flex grow flex-col">
					<Outlet />
				</div>
			</div>
			<UniqueNameRequiredModal
				open={uniqueNameRequiredOpen}
				onClose={() => {
					setUniqueNameRequiredOpen(false)
				}}
				itemType="data set"
			/>
		</>
	)
}
