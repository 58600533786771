import {Label, Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon} from '@heroicons/react/24/outline'
import {clsx} from 'clsx'
import {ReactNode, useEffect, useRef} from 'react'
import {type FieldValues, useController, type UseControllerProps} from 'react-hook-form'

export interface SelectOption {
	value: string | number
	label: string
}

interface SelectProps<TFieldValues extends FieldValues> extends UseControllerProps<TFieldValues> {
	options: SelectOption[]
	children: ReactNode
	placeholder?: string
	disabled?: boolean
	className?: string
	dividerIndex?: number
}

export function Select<TFieldValues extends FieldValues>({
	options,
	children,
	placeholder = 'Select an option',
	disabled = false,
	className,
	dividerIndex,
	...controllerProps
}: SelectProps<TFieldValues>) {
	const {
		field,
		fieldState: {error},
	} = useController(controllerProps)

	const buttonRef = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		const button = buttonRef.current
		if (!button) return

		const handleBlur = () => {
			field.onBlur()
		}

		button.addEventListener('blur', handleBlur)
		return () => {
			button.removeEventListener('blur', handleBlur)
		}
	}, [field.onBlur])

	const selectedOption = options.find((option) => option.value === field.value)

	return (
		<Listbox
			as="div"
			{...field}
			defaultValue=""
			disabled={disabled}
			className={className}
		>
			<Label>{children}</Label>
			<div className="relative mt-2 max-w-form-sm">
				<ListboxButton
					ref={buttonRef}
					className={clsx(
						error != null
							? '!focus:ring-uom-red-light-100 !focus:border-uom-red-light-100 !focus:outline-none !border-uom-red-light-100 !ring-uom-red-light-100'
							: 'border-uom-grey-light-100 ring-transparent focus:border-uom-yellow-100 focus:ring-uom-yellow-100',
						'relative mt-[4px] w-full max-w-form-sm rounded-[6px] border bg-uom-heritage-100 px-[13px] py-[9px] ring-1',
					)}
				>
					<span className={clsx(disabled && 'text-gray-400', 'flex items-center')}>
						{field.value === '' ? <span className="text-gray-400">{placeholder}</span> : selectedOption ? selectedOption.label : 'N/A'}
					</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<ChevronDownIcon
							className="h-5 w-5 text-uom-grey-light-50"
							aria-hidden="true"
						/>
					</span>
				</ListboxButton>

				<ListboxOptions className="absolute z-10 mt-1 max-h-[600px] w-full overflow-auto rounded-md bg-uom-heritage-75 py-1 text-base text-white ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm">
					{options.map((option, index) => (
						<ListboxOption
							key={option.value}
							className={clsx(
								dividerIndex === index && index > 0 && 'border-t border-gray-400',
								'group relative cursor-default select-none py-2 pl-3 pr-9 text-white data-[active]:bg-uom-heritage-100',
							)}
							value={option.value}
						>
							<div className="flex items-center">
								<span className="ml-3 block truncate group-data-[selected]:font-semibold">{option.label}</span>
							</div>
							<span className="absolute inset-y-0 right-0 flex items-center pr-4 text-uom-yellow-100 [.group:not([data-selected])_&]:hidden">
								<CheckIcon
									className="h-5 w-5"
									aria-hidden="true"
								/>
							</span>
						</ListboxOption>
					))}
				</ListboxOptions>
			</div>
			{error && (
				<p
					role="alert"
					className="mt-[4px] text-uom-red-light-100"
				>
					{error.message ?? 'Please select an option'}
				</p>
			)}
		</Listbox>
	)
}
