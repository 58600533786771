import {ExclamationTriangleIcon} from '@heroicons/react/24/solid'
import {clsx} from 'clsx'
import {produce} from 'immer'
import {type ChangeEvent, useMemo, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useInterval} from 'usehooks-ts'
import {InfoModalChunkSize} from '~/components/InfoButtonAndModal.tsx'
import {InfoModal} from '~/components/InfoModal.tsx'
import {LoadingAnimationCentered} from '~/components/LoadingAnimation.tsx'
import {Select} from '~/components/Select.tsx'
import {type DataSet, type DataSource, isContinuePollingDatasetStatus} from '~/model.ts'
import {EditFile} from '~/pages/dataSets/EditFile.tsx'
import {FileSectionErrors} from '~/pages/dataSets/FileSectionErrors.tsx'
import {ProcessingError} from '~/pages/dataSets/ProcessingError.tsx'
import {useFileUpload} from '~/pages/dataSets/useFileUpload.ts'
import {queryClient, useDataSet, useDataSetProcessFiles, useDataSources, useReferenceData, useUpdateDataSet} from '~/services/api.ts'
import {capitalizeFirstChar, convertToTitleCase, numbersToSelectOptions} from '~/util.ts'

export const DataSetFiles = () => {
	const {dataSetId} = useParams()

	const {data: dataSet, refetch: refetchDataSet} = useDataSet(dataSetId)
	const {data: dataSourcesResponse, refetch: refetchDataSources} = useDataSources(dataSetId)
	const {mutate: updateDataSetApi} = useUpdateDataSet()
	const {mutateAsync: dataSetProcessFilesApi} = useDataSetProcessFiles()
	const {data: referenceData} = useReferenceData()

	const fileInputRef = useRef<HTMLInputElement>(null)

	const [editFile, setEditFile] = useState<null | DataSource>(null)
	const [justStartedProcessing, setJustStartedProcessing] = useState(false)
	const [showFileTypesModal, setShowFileTypesModal] = useState(false)

	const {fileExtensionError, fileSizeError, totalUploadSize, fileUploadProgress, uploadingFiles, uploading, uploadFiles, clearFileErrors} = useFileUpload(dataSetId)

	const {getValues, control, watch} = useForm<DataSet>({
		defaultValues: dataSet,
		shouldFocusError: false,
	})

	const polling = isContinuePollingDatasetStatus(dataSourcesResponse?.dataset_status)
	const active = polling || uploading || justStartedProcessing

	const formChunkSize = watch('vector_configuration.chunk_size')

	useInterval(
		() => {
			if (isContinuePollingDatasetStatus(dataSourcesResponse?.dataset_status)) {
				void refetchDataSources()
			} else {
				void refetchDataSet()
				void refetchDataSources()
				void queryClient.invalidateQueries({queryKey: ['dataSets']})
			}
		},
		polling ? 5000 : null,
	)

	const chunkSizes = useMemo(() => {
		if (referenceData?.chunk_size == null) return []
		const chunkSizes: number[] = []
		for (let i = referenceData.chunk_size.min; i <= referenceData.chunk_size.max; i += referenceData.chunk_size.step ?? 64) {
			chunkSizes.push(i)
		}
		return chunkSizes
	}, [referenceData?.chunk_size])

	if (dataSet == null || dataSetId == null || dataSourcesResponse == null) return <LoadingAnimationCentered />

	const progress = Object.values(fileUploadProgress).reduce((accumulator, progress) => accumulator + progress, 0)
	const progressPercentage = Math.min(Math.round((progress / totalUploadSize) * 100), 100)

	const loadFiles = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return

		let dataSetToSend = dataSet
		if (getValues().vector_configuration.chunk_size != null && dataSet.vector_configuration.chunk_size !== getValues().vector_configuration.chunk_size) {
			dataSetToSend = produce(dataSet, (draft) => {
				draft.vector_configuration.chunk_size = getValues().vector_configuration.chunk_size ?? 0
			})
			updateDataSetApi(dataSetToSend)
		}
		setJustStartedProcessing(true)
		await uploadFiles(event.target.files)
		setTimeout(() => {
			setJustStartedProcessing(false)
		}, 3000)
		await dataSetProcessFilesApi({dataSet: dataSetToSend})
		void refetchDataSources()
	}

	const dataSourcesHasComment = dataSourcesResponse.datasources.some((dataSource) => dataSource.comment.length > 0)

	const isViewer = dataSet.role === 'VIEWER'

	async function reprocessFiles() {
		if (!dataSet) return
		const dataSetToSend = produce(dataSet, (draft) => {
			draft.vector_configuration.chunk_size = formChunkSize
		})
		updateDataSetApi(dataSetToSend)
		setJustStartedProcessing(true)
		await dataSetProcessFilesApi({dataSet: dataSetToSend, forceReprocess: true})
		void refetchDataSources()
		setTimeout(() => {
			void refetchDataSources()
			setJustStartedProcessing(false)
		}, 3000)
	}

	return (
		<div className="grow overflow-y-auto px-[84px] py-[24px] text-sm font-normal leading-5">
			<h1 className="font-lead text-2xl font-medium leading-8">Data files</h1>
			<p className="mt-[8px] max-w-content-sm text-base font-normal leading-6">The files you upload in this section will be processed and incorporated into a custom data set vector.</p>
			<div className="mt-[24px] grid w-full max-w-content-lg grid-cols-5 gap-[24px] rounded-[8px] border border-gray-400 p-[24px]">
				<div>
					<div className="pb-[4px] font-medium">Data structure</div>
					<div className="text-uom-grey-light-50">Unstructured</div>
				</div>
				<div>
					<div className="pb-[4px] font-medium">Access</div>
					<div className="text-uom-grey-light-50">Private</div>
				</div>
				<div>
					<div className="pb-[4px] font-medium">Chunk Type</div>
					<div className="text-uom-grey-light-50">{capitalizeFirstChar(dataSet.vector_configuration.chunk_type)}</div>
				</div>
				<div>
					<div className="pb-[4px] font-medium">Chunk size</div>
					<div className="text-uom-grey-light-50">{dataSet.vector_configuration.chunk_size === 0 ? 'N/A' : dataSet.vector_configuration.chunk_size}</div>
				</div>
				<div>
					<div className="pb-[4px] font-medium">Status</div>
					<div className="text-uom-grey-light-50">{dataSourcesResponse.dataset_status}</div>
				</div>
			</div>
			{!isViewer && (
				<form>
					<Select
						options={numbersToSelectOptions(chunkSizes)}
						name="vector_configuration.chunk_size"
						control={control}
						disabled={active}
					>
						<div className="mt-[24px] flex max-w-form-sm items-center justify-between font-medium">
							<label className="block grow">Chunk size</label>
							<InfoModalChunkSize />
						</div>
					</Select>
				</form>
			)}
			{formChunkSize != null && dataSet.vector_configuration.chunk_size !== formChunkSize && !active && dataSourcesResponse.datasources.length > 0 && (
				<>
					<div className="mt-[24px] flex max-w-content-sm items-center gap-x-[12px] rounded-r-[6px] border-l-4 border-yellow-400 bg-yellow-50 p-[16px]">
						<ExclamationTriangleIcon className="h-[27px] text-yellow-400" />
						<div className="text-sm font-medium leading-none text-yellow-800">All new and existing files will be processed at {formChunkSize} tokens.</div>
					</div>
					<button
						className="mt-[24px] flex items-center rounded-[4px] border border-gray-300 bg-white px-[11px] py-[7px] text-gray-700 hover:bg-gray-200 disabled:cursor-not-allowed disabled:border-[#4a505d] disabled:bg-[#585d67] disabled:text-[#1c2434]"
						onClick={() => {
							void reprocessFiles()
						}}
					>
						Reprocess files at {formChunkSize} tokens
					</button>
				</>
			)}

			{!isViewer && (
				<>
					<h3 className="mt-[48px] text-lg font-medium leading-6">Upload files</h3>
					<div className="mt-[2px] text-uom-grey-light-50">
						Files will start processing as soon as selected. Accepted files include: {referenceData?.dataset_file_types.split(',.').slice(0, 8).join(', .')}.{' '}
						<button
							className="inline underline"
							onClick={() => {
								setShowFileTypesModal(true)
							}}
						>
							View all file types
						</button>
						.
					</div>
					<input
						type="file"
						ref={fileInputRef}
						className="hidden"
						onChange={(event) => void loadFiles(event)}
						multiple={true}
						accept={referenceData?.dataset_file_types}
					/>
					<button
						className="mt-[24px] flex items-center rounded-[4px] border border-gray-300 bg-white px-[11px] py-[7px] text-gray-700 hover:bg-gray-200 disabled:cursor-not-allowed disabled:border-[#4a505d] disabled:bg-[#585d67] disabled:text-[#1c2434]"
						onClick={() => {
							fileInputRef.current?.click()
							clearFileErrors()
						}}
						disabled={active}
					>
						Select files
					</button>
				</>
			)}
			{uploading && (
				<div className="mt-[48px] flex flex-col gap-y-[24px]">
					<div>
						{uploadingFiles.length > 0 && (
							<>
								<div>Uploading files... {progressPercentage}%</div>
								<div className="mt-[12px]h-[8px] w-full max-w-[328px] overflow-hidden rounded-[2px] bg-gray-600">
									<div
										className="h-full overflow-hidden rounded-[2px] bg-white"
										style={{width: progressPercentage.toString() + '%'}}
									></div>
								</div>
							</>
						)}
					</div>
				</div>
			)}
			{(polling || justStartedProcessing) && (
				<div className="mt-[48px]">
					<div>Processing files...</div>
					<div className="mt-[12px] h-[8px] w-full max-w-[328px] overflow-hidden rounded-[2px] bg-gray-600">
						<div className="h-full w-full origin-left-right animate-progress rounded-[2px] bg-white" />
					</div>
				</div>
			)}
			{(fileExtensionError || fileSizeError) && (
				<FileSectionErrors
					fileExtensionError={fileExtensionError}
					fileSizeError={fileSizeError}
				/>
			)}
			{dataSourcesResponse.dataset_status !== 'CREATED' && dataSourcesResponse.datasources.length > 0 && (
				<div className="mt-[48px] max-w-content-lg">
					{isViewer && <h3 className="mb-[12px] mt-[48px] text-lg font-medium leading-6">Files</h3>}
					<div className={clsx(polling && 'opacity-30', 'w-full max-w-content-lg overflow-hidden rounded-[8px] border border-gray-400')}>
						<div className="max-h-[418px] w-full overflow-auto">
							<table className="w-full border-collapse">
								<thead className="">
									<tr className="whitespace-nowrap border-b border-gray-400 bg-uom-heritage-150 py-[8px] text-left text-xs font-medium leading-4 tracking-wider">
										<th className="px-[24px] py-[8px]">TITLE</th>
										<th className="px-[24px] py-[8px]">STATUS</th>
										<th className="px-[24px] py-[8px]">SOURCE</th>
										{dataSourcesHasComment && <th className="px-[24px] py-[8px]">COMMENT</th>}
									</tr>
								</thead>
								<tbody>
									{dataSourcesResponse.datasources.map((dataSource, index) => (
										<tr
											role="button"
											onClick={() => {
												setEditFile(dataSource)
											}}
											key={index}
											className="whitespace-nowrap border-b border-t border-gray-400 bg-uom-heritage-100 hover:bg-uom-heritage-150"
										>
											<td className="px-[24px] py-[8px]">{convertToTitleCase(dataSource.title)}</td>
											<td className="px-[24px] py-[8px]">
												<div
													className={clsx(
														dataSource.status === 'READY' ? 'bg-uom-green-light-100' : dataSource.status === 'ERROR' ? 'bg-uom-red-light-100' : 'bg-orange-500',
														'max-w-max rounded-[4px] px-[10px] py-[2px] text-xs font-medium leading-4 text-uom-heritage-100',
													)}
												>
													{dataSource.status}
												</div>
											</td>
											<td className="px-[24px] py-[8px]">{dataSource.title + dataSource.file_type}</td>
											{dataSourcesHasComment && <td className="px-[24px] py-[8px]">{dataSource.comment}</td>}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
			{dataSet.status === 'ERROR' && !active && <ProcessingError />}
			<EditFile
				dataSet={dataSet}
				dataSources={dataSourcesResponse.datasources}
				dataSource={editFile ?? undefined}
				onCancel={() => {
					setEditFile(null)
				}}
				onApply={() => {
					setEditFile(null)
				}}
			/>
			<InfoModal
				heading="Accepted file types"
				open={showFileTypesModal}
				close={() => {
					setShowFileTypesModal(false)
				}}
			>
				Accepted file types include: {referenceData?.dataset_file_types.replaceAll(',.', ', .')}
			</InfoModal>
		</div>
	)
}
