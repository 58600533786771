import {useEffect, useState} from 'react'
import {useAuth} from 'react-oidc-context'
import {Outlet} from 'react-router-dom'
import {NetworkErrorHandling} from '~/components/NetworkErrorHandling.tsx'
import {WindowsSizeIndicator} from '~/components/WindowsSizeIndicator.tsx'
import {MaintenancePage} from '~/pages/maintenance/MaintenancePage.tsx'
import {useDataSets, useProjects, useReferenceData, useThreads} from '~/services/api.ts'
import {Navbar} from './components/Navbar.tsx'

export const Root = () => {
	const auth = useAuth()
	const [bypassMaintenance, setBypassMaintenance] = useState(false)

	useEffect((): void => {
		if (import.meta.env.VITE_DOWN_FOR_MAINTENANCE === 'true' && !bypassMaintenance) {
			return
		}
		if (!auth.isAuthenticated && !auth.isLoading) {
			sessionStorage.setItem('redirect-after-login', window.location.pathname)
			void auth.signinRedirect()
		} else if (window.location.pathname === '/') {
			window.location.href = '/securechat'
		}
	}, [auth, bypassMaintenance])

	useThreads('playground')
	useProjects()
	useDataSets()
	useReferenceData()

	if (import.meta.env.VITE_DOWN_FOR_MAINTENANCE === 'true' && !bypassMaintenance) {
		return (
			<MaintenancePage
				onBypass={() => {
					setBypassMaintenance(true)
				}}
			/>
		)
	}

	return (
		<>
			{auth.isAuthenticated && (
				<div className="flex h-full flex-col">
					<Navbar />
					<Outlet />
				</div>
			)}
			<NetworkErrorHandling />
			{import.meta.env.DEV && <WindowsSizeIndicator />}
		</>
	)
}
