import {Draw} from '~/components/Draw.tsx'
import {type DataSet} from '~/model.ts'
import {formatDate} from '~/util.ts'

interface Props {
	dataSet?: DataSet
	onDone: () => void
}

export const GlobalDataSetInfo = ({dataSet, onDone}: Props) => {
	return (
		<Draw
			open={dataSet != null}
			onDone={onDone}
		>
			{dataSet != null && (
				<div className="overflow-y-auto px-[120px] py-[24px] text-sm font-normal leading-5 text-white">
					<div className="max-w-content">
						<h2 className="font-lead text-2xl font-medium leading-8">Global data set information</h2>
					</div>

					<h3 className="mt-[48px] text-lg font-medium leading-6">Name</h3>
					<p className="mt-[8px]">{dataSet.name}</p>

					<h3 className="mt-[24px] text-lg font-medium leading-6">Description</h3>
					{dataSet.description.length > 0 ? <p className="mt-[8px]">{dataSet.description}</p> : <p className="mt-[8px] text-gray-500">(No description provided)</p>}

					<div className="mt-[48px] grid w-full max-w-content grid-cols-3 gap-[24px] rounded-[8px] border border-gray-400 p-[24px]">
						<div className="">
							<div className="pb-[4px] font-medium">Last update</div>
							<div className="">{formatDate(dataSet.modified_datetime)}</div>
						</div>
						<div className="">
							<div className="pb-[4px] font-medium">Chunk size</div>
							<div className="">{dataSet.vector_configuration.chunk_size}</div>
						</div>
						<div className="">
							<div className="pb-[4px] font-medium">RAG retrieval count</div>
							<div className="">{dataSet.query_configuration.size}</div>
						</div>
					</div>
				</div>
			)}
		</Draw>
	)
}
