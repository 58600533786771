import {QueryClient, useMutation, useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {produce} from 'immer'
import {useAtom} from 'jotai/index'
import {type DataSet, DataSource, DataSourcesResponse, type NewProject, type Project, type RagSources, type ReferenceData, S3PresignedUrlsResponse, type Thread, type ThreadSummery} from '~/model.ts'
import {getToken} from '~/services/auth.ts'
import {selectedFilesAtom} from '~/services/state.ts'

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1,
			retryDelay: 500,
			staleTime: 300000, // 5 minutes
			gcTime: Infinity,
		},
	},
})

export const axiosAuthInstance = axios.create({
	baseURL: import.meta.env.VITE_API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'spark-ai-app-id': import.meta.env.VITE_SPARK_AI_APP_ID,
		'spark-ai-app-key': import.meta.env.VITE_SPARK_AI_APP_KEY,
	},
})

// Request interceptor for API calls
axiosAuthInstance.interceptors.request.use((config) => {
	const token = getToken()
	if (!token) {
		throw new Error('No token found')
	}
	config.headers.Authorization = `Bearer ${token}`
	return config
})

export function useReferenceData() {
	return useQuery({
		queryKey: ['referenceData'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{reference_data: ReferenceData}>(`/referencedata`)
			return data.reference_data
		},
		staleTime: 1000 * 60 * 60 * 24, // 24 hours
	})
}

// Chat completion

export function useChatCompletion(projectId?: string, threadId?: string) {
	const {data: referenceData} = useReferenceData()
	const {data: threadData} = useThread(projectId, threadId)
	const [selectedFiles, setSelectedFiles] = useAtom(selectedFilesAtom)

	return useMutation({
		mutationFn: async (variables: {message: string; config: Project}) => {
			const attachments = Object.values(selectedFiles)
				.filter((file) => file.status === 'processed')
				.map((file) => file.attachment)
				.filter((attachment) => attachment != null)
			const newThread: Thread =
				threadData == null
					? {
							SK: '',
							project: projectId ?? 'playground',
							ui_messages: [
								{
									role: 'user',
									content: variables.message,
									attachments: attachments,
									llm_configuration: variables.config.llm_configuration,
									query_configuration: projectId != null ? variables.config.query_configuration : undefined,
									prompt_templates: variables.config.prompt_templates,
								},
							],
						}
					: produce(threadData, (draft) => {
							draft.ui_messages.push({
								role: 'user',
								content: variables.message,
								attachments: attachments,
								llm_configuration: variables.config.llm_configuration,
								query_configuration: variables.config.query_configuration,
								prompt_templates: variables.config.prompt_templates,
							})
						})
			setSelectedFiles({})
			const {data} = await axiosAuthInstance.post<{thread: Thread}>(
				'/',
				{
					thread: newThread,
				},
				{baseURL: projectId == null ? referenceData?.chat_url : referenceData?.ragchat_url},
			)
			return data.thread
		},
		onSuccess: (data) => {
			queryClient.setQueryData(['projects', projectId ?? 'playground', 'threads', data.SK], data)
			queryClient.setQueryData(['projects', projectId ?? 'playground', 'threads'], (oldData: Thread[] | undefined) => {
				if (oldData == null) return [data]
				const index = oldData.findIndex((thread) => thread.SK === data.SK)
				if (index === -1) return [data, ...oldData]
				return produce(oldData, (draft) => {
					draft[index] = data
				})
			})
		},
	})
}

//  Thread

export function useThreads(projectId?: string) {
	return useQuery({
		queryKey: ['projects', projectId ?? 'playground', 'threads'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{threads: ThreadSummery[]}>(`/projects/${projectId ?? 'playground'}/threads`)
			return data.threads
		},
	})
}

export function useThread(projectId?: string, threadId?: string) {
	return useQuery({
		queryKey: ['projects', projectId ?? 'playground', 'threads', threadId],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{thread: Thread}>(`/projects/${projectId ?? 'playground'}/threads/${threadId}`)
			return data.thread
		},
		enabled: threadId != undefined,
	})
}

export const useDeleteThread = () => {
	return useMutation({
		// Optimistic update
		onMutate: ({threadId, projectId}) => {
			queryClient.setQueryData(['projects', projectId ?? 'playground', 'threads'], (oldData: Thread[] | undefined) => {
				if (oldData == null) return []
				return produce(oldData, (draft) => {
					const index = draft.findIndex((thread) => thread.SK === threadId)
					if (index !== -1) draft.splice(index, 1)
				})
			})
		},
		mutationFn: async (variables: {threadId: string; projectId?: string}) => {
			await axiosAuthInstance.delete(`/projects/${variables.projectId ?? 'playground'}/threads/${variables.threadId}`)
		},
	})
}

export const useRenameThread = () => {
	return useMutation({
		// Optimistic update
		onMutate: ({threadId, projectId, newName}) => {
			queryClient.setQueryData<Thread[] | undefined>(['projects', projectId ?? 'playground', 'threads'], (oldData) => {
				if (oldData == null) return []
				return produce(oldData, (draft) => {
					const renamedThread = draft.find((thread) => thread.SK === threadId)
					if (renamedThread != null) {
						renamedThread.thread_name = newName
					}
				})
			})
			queryClient.setQueryData<Thread | undefined>(['projects', projectId ?? 'playground', 'threads', threadId], (oldData) => {
				if (oldData == null) return undefined
				return produce(oldData, (draft) => {
					draft.thread_name = newName
				})
			})
		},
		mutationFn: async (variables: {threadId: string; newName: string; projectId?: string}) => {
			return await axiosAuthInstance.put<{thread: Thread}>(`/projects/${variables.projectId ?? 'playground'}/threads/${variables.threadId}`, {
				thread: {
					thread_name: variables.newName,
				},
			})
		},
	})
}

// Project

export function useProjects() {
	return useQuery({
		queryKey: ['projects'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{projects: Project[]}>(`/projects`)
			return data.projects
		},
	})
}

export function useProject(projectId?: string) {
	return useQuery({
		queryKey: ['projects', projectId],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{project: Project}>(`/projects/${projectId}`)
			return data.project
		},
		enabled: projectId != null,
	})
}

export function useCreateProject() {
	return useMutation({
		mutationFn: async (project: NewProject) => {
			const {data} = await axiosAuthInstance.post<{project: Project}>(`/projects`, {
				project,
			})
			return data.project
		},
		onSuccess: (project) => {
			queryClient.setQueryData<Project[] | undefined>(['projects'], (oldData) => {
				if (oldData == null) return [project]
				return produce(oldData, (draft) => {
					draft.push(project)
				})
			})
			queryClient.setQueryData<Project | undefined>(['projects', project.SK], () => {
				return project
			})
		},
	})
}

export function useUpdateProject() {
	return useMutation({
		// Optimistic update
		onMutate: (project: Project) => {
			queryClient.setQueryData<Project | undefined>(['projects', project.SK], () => {
				return project
			})
			queryClient.setQueryData<Project[] | undefined>(['projects'], (oldData) => {
				if (oldData == null) return []
				return produce(oldData, (draft) => {
					const index = draft.findIndex((p) => p.SK === project.SK)
					draft[index] = project
				})
			})
		},
		mutationFn: async (project: Project) => {
			const {data} = await axiosAuthInstance.put<{project: Project}>(`/projects/${project.SK ? project.SK : 'playground'}`, {
				project,
			})
			return data.project
		},
	})
}

export const useDeleteProject = () => {
	return useMutation({
		// Optimistic update
		onMutate: (project: Project) => {
			queryClient.setQueryData<Project[] | undefined>(['projects'], (oldData) => {
				if (oldData == null) return []
				return produce(oldData, (draft) => {
					const index = draft.findIndex((p) => p.SK === project.SK)
					draft.splice(index, 1)
				})
			})
			queryClient.setQueryData<DataSet | undefined>(['projects', project.SK], () => {
				return undefined
			})
		},
		mutationFn: async (project: Project) => {
			await axiosAuthInstance.delete(`/projects/${project.SK}`)
		},
	})
}

// DataSet

export function useDataSets() {
	return useQuery({
		queryKey: ['dataSets'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{datasets: DataSet[]}>(`/datasets`)
			const personalDataSets = data.datasets.filter((dataSet) => dataSet.scope === 'private')
			const globalDataSets = data.datasets.filter((dataSet) => dataSet.scope === 'global')
			return {personalDataSets, globalDataSets, combinedDataSets: data.datasets}
		},
	})
}

export function useDataSet(id?: string) {
	return useQuery({
		queryKey: ['dataSets', id],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{dataset: DataSet}>(`/datasets/${id}`)
			return data.dataset
		},
		enabled: id != null,
	})
}

export const useUpdateDataSet = () => {
	return useMutation({
		// Optimistic update
		onMutate: (dataSet: DataSet) => {
			queryClient.setQueryData<{personalDataSets: DataSet[]; globalDataSets: DataSet[]} | undefined>(['dataSets'], (oldData) => {
				if (oldData == null) return {personalDataSets: [], globalDataSets: []}
				return produce(oldData, (draft) => {
					const index = draft.personalDataSets.findIndex((ds) => ds.SK === dataSet.SK)
					draft.personalDataSets[index] = dataSet
				})
			})
			queryClient.setQueryData<DataSet | undefined>(['dataSets', dataSet.SK], () => {
				return dataSet
			})
		},
		mutationFn: async (dataSet: DataSet) => {
			const {data} = await axiosAuthInstance.put<{dataset: DataSet}>(`/datasets/${dataSet.SK}`, {
				dataset: dataSet,
			})
			return data.dataset
		},
	})
}

export const useCreateDataSet = () => {
	return useMutation({
		mutationFn: async (dataSet: DataSet) => {
			const {data} = await axiosAuthInstance.post<{dataset: DataSet}>(`/datasets`, {
				dataset: dataSet,
			})
			return data.dataset
		},
		onSuccess: (dataSet) => {
			queryClient.setQueryData<{personalDataSets: DataSet[]; globalDataSets: DataSet[]} | undefined>(['dataSets'], (oldData) => {
				if (oldData == null) return {personalDataSets: [], globalDataSets: []}
				return produce(oldData, (draft) => {
					draft.personalDataSets.push(dataSet)
				})
			})
			queryClient.setQueryData<DataSet | undefined>(['dataSets', dataSet.SK], () => {
				return dataSet
			})
		},
	})
}

export const useDeleteDataSet = () => {
	return useMutation({
		// Optimistic update
		onMutate: (dataSet: DataSet) => {
			queryClient.setQueryData<{personalDataSets: DataSet[]; globalDataSets: DataSet[]} | undefined>(['dataSets'], (oldData) => {
				if (oldData == null) return {personalDataSets: [], globalDataSets: []}
				return produce(oldData, (draft) => {
					const index = draft.personalDataSets.findIndex((ds) => ds.SK === dataSet.SK)
					draft.personalDataSets.splice(index, 1)
				})
			})
			queryClient.setQueryData<DataSet | undefined>(['dataSets', dataSet.SK], () => {
				return undefined
			})
		},
		mutationFn: async (dataSet: DataSet) => {
			await axiosAuthInstance.delete(`/datasets/${dataSet.SK}`)
		},
	})
}

export const useDataSetCreateS3Urls = () => {
	return useMutation({
		mutationFn: async (variables: {dataSetId?: string; files: string[]}) => {
			const {data} = await axiosAuthInstance.post<S3PresignedUrlsResponse>(`/datasets/${variables.dataSetId}/s3presignedurls`, {
				files: variables.files.map((file) => ({name: file})),
			})
			return data
		},
	})
}

export const useDataSetProcessFiles = () => {
	return useMutation({
		mutationFn: async ({dataSet, forceReprocess}: {dataSet: DataSet; forceReprocess?: boolean}) => {
			const {data} = await axiosAuthInstance.post<unknown>(`/datasets/${dataSet.SK}/process_files`, {
				forceReprocess: !!forceReprocess,
				vector_configuration: {
					chunk_type: dataSet.vector_configuration.chunk_type,
					chunk_size: dataSet.vector_configuration.chunk_size,
				},
			})
			return data
		},
	})
}

export function useDataSources(dataSetId?: string) {
	return useQuery({
		queryKey: ['dataSets', dataSetId, 'dataSources'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<DataSourcesResponse>(`/datasets/${dataSetId}/datasources`)
			return data
		},
		enabled: dataSetId != null,
	})
}

export const useUpdateDataSources = () => {
	return useMutation({
		mutationFn: async ({dataSources, dataSetId}: {dataSources: DataSource[]; dataSetId: string}) => {
			const {data} = await axiosAuthInstance.put<DataSourcesResponse>(`/datasets/${dataSetId}/datasources`, {datasources: dataSources})
			return data
		},
		onSuccess: (data, variables) => {
			queryClient.setQueryData<DataSourcesResponse | undefined>(['dataSets', variables.dataSetId, 'dataSources'], () => {
				return data
			})
		},
	})
}

// Message

export const useMessage = (projectId?: string, threadId?: string, messageId?: string) => {
	return useQuery({
		queryKey: ['projects', projectId, 'threads', threadId, 'messages', messageId],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{ragsources: RagSources}>(`/projects/${projectId}/threads/${threadId}/${messageId}`)
			return data.ragsources
		},
		enabled: projectId != null && threadId != null && messageId != null,
	})
}

export async function convertBinaryFileToText(file: File) {
	const formData = new FormData()
	formData.append('file', file)

	const {data} = await axiosAuthInstance.post<{file_name: string; file_size: number; file_type: string; extracted_content: string}>('/convertfile', formData)

	return data
}
