import {OidcMetadata, User, WebStorageStateStore} from 'oidc-client-ts'
import {useEffect, useMemo, useState} from 'react'
import {AuthProviderProps, hasAuthParams, useAuth} from 'react-oidc-context'
import {useLocalStorage} from 'usehooks-ts'

export const oidcConfig: AuthProviderProps = {
	authority: 'https://sso.unimelb.edu.au/',
	client_id: '0oa36hgcm3AjUmpwv3l7',
	redirect_uri: window.location.origin + '/login/callback/',
	scope: 'openid email profile groups',
	userStore: new WebStorageStateStore({store: window.localStorage}),
}

interface UserNames {
	given_name?: string
	family_name?: string
}

export const useUserInfo = () => {
	const auth = useAuth()
	const [userInfo, setUserInfo] = useLocalStorage<UserNames | undefined>('user-info', undefined)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (userInfo != null || !auth.isAuthenticated || isLoading) return

		const fetchData = async () => {
			setIsLoading(true)
			try {
				const metadataUrl = auth.settings.metadataUrl
				if (metadataUrl == null) return

				const metadata = (await fetch(metadataUrl).then((res) => res.json())) as OidcMetadata | null
				if (metadata == null) return

				const userInfoData = (await fetch(metadata.userinfo_endpoint, {
					headers: {
						Authorization: `Bearer ${auth.user?.access_token}`,
					},
				}).then((res) => res.json())) as UserNames | null

				if (userInfoData == null) return
				setUserInfo(userInfoData)
			} catch (error) {
				console.error('Error fetching user info:', error)
			} finally {
				setIsLoading(false)
			}
		}

		void fetchData()
	}, [auth, userInfo, setUserInfo, isLoading])

	return {userInfo, isLoading}
}

export const useUserInitials = () => {
	const {userInfo} = useUserInfo()

	const userInitials = useMemo(() => {
		if (!userInfo) return undefined
		const {given_name, family_name} = userInfo
		return given_name && family_name ? `${given_name.charAt(0)}${family_name.charAt(0)}` : undefined
	}, [userInfo])

	return userInitials
}

export function LoginCallback() {
	console.log('LoginCallback')
	const auth = useAuth()
	const [hasTriedSignin, setHasTriedSignin] = useState(false)

	// automatically sign-in
	useEffect(() => {
		console.log('LoginCallback', auth.isAuthenticated, auth.activeNavigator, auth.isLoading, hasTriedSignin)
		if (auth.isAuthenticated) {
			console.log('Authenticated')
			const redirectAfterLogin = sessionStorage.getItem('redirect-after-login')
			sessionStorage.removeItem('redirect-after-login')
			window.location.href = redirectAfterLogin ? redirectAfterLogin : '/'
		}
		if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
			void auth.signinRedirect()
			setHasTriedSignin(true)
		}
	}, [auth, hasTriedSignin])
	return null
}

export function getToken() {
	const oidcStorage = localStorage.getItem('oidc.user:https://sso.unimelb.edu.au/:0oa36hgcm3AjUmpwv3l7')
	if (!oidcStorage) {
		return null
	}
	return User.fromStorageString(oidcStorage).access_token
}
