import {clsx} from 'clsx'
import {useEffect, useRef} from 'react'
import {Draw} from '~/components/Draw.tsx'
import {Attachment} from '~/model.ts'
import {convertBytesNumberToString} from '~/util.ts'

interface Props {
	attachments: Attachment[] | null
	onDone: () => void
	showAttachmentIndex?: number | null
}

export const AttachedFilesInfo = ({attachments, onDone, showAttachmentIndex}: Props) => {
	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const scrollToIndexRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const attemptScroll = () => {
			if (scrollToIndexRef.current) {
				scrollToIndexRef.current.scrollIntoView({behavior: 'smooth'})
			} else {
				window.setTimeout(attemptScroll, 100)
			}
		}

		if (attachments?.length) {
			if (showAttachmentIndex != null && showAttachmentIndex > 0) {
				attemptScroll()
			} else {
				scrollContainerRef.current?.scrollTo(0, 0)
			}
		}
	}, [attachments, showAttachmentIndex])

	return (
		<Draw
			open={attachments != null && attachments.length > 0}
			onDone={onDone}
		>
			<div
				ref={scrollContainerRef}
				className="overflow-y-auto px-[120px] py-[24px] text-sm font-normal leading-5 text-white"
			>
				<div className="max-w-content">
					<h2 className="font-lead text-2xl font-medium leading-8">Uploaded file</h2>
					<p className="mt-1 text-base font-normal leading-6">The AI model has extracted the following text content from the uploaded file.</p>
				</div>
				<div className={clsx('mt-[24px] flex w-full max-w-content flex-col gap-y-[48px]')}>
					{attachments?.map((attachment, index) => {
						return (
							<div
								ref={showAttachmentIndex === index ? scrollToIndexRef : null}
								key={attachment.file_name + attachment.file_size.toString()}
								className={clsx('flex flex-col gap-y-[8px]')}
							>
								<div className="text-[18px] font-[600] leading-[140%]">{attachment.file_name}</div>
								<div className={clsx('max-w-max rounded-[4px] bg-uom-heritage-75 px-[8px] py-[2px] text-[14px] font-[600] leading-[140%]')}>{convertBytesNumberToString(attachment.file_size)}</div>
								<div className="whitespace-pre-line break-words text-[16px] font-[400] leading-[140%]">{attachment.extracted_content}</div>
							</div>
						)
					})}
				</div>
			</div>
		</Draw>
	)
}
