import {clsx} from 'clsx'
import {format} from 'date-fns'
import {useParams} from 'react-router-dom'
import {Draw} from '~/components/Draw.tsx'
import {type UIMessage} from '~/model.ts'

interface Props {
	message?: UIMessage
	onDone: () => void
}

export const MessageInfo = ({message, onDone}: Props) => {
	const {projectId} = useParams()

	const isPlayground = projectId == null

	return (
		<Draw
			open={message != null}
			onDone={onDone}
		>
			<div className="overflow-y-auto px-[120px] py-[24px] text-sm font-normal leading-5 text-white">
				<div className="max-w-content">
					<h2 className="font-lead text-2xl font-medium leading-8">Message information</h2>
					<p className="mt-1 text-base font-normal leading-6">Message information showcases the configuration and token usage of the specific message response from the AI model.</p>
				</div>
				<div
					className={clsx(
						isPlayground ? 'max-w-content' : 'max-w-content-lg lg:grid-cols-4',
						'mt-[24px] grid w-full gap-[24px] rounded-[8px] border border-uom-grey-light-100 p-[24px] min-[726px]:grid-cols-2 min-[866px]:grid-cols-3',
					)}
				>
					{!isPlayground && (
						<>
							<div>
								<div className="pb-[4px] font-medium">Data set</div>
								<div className="text-uom-grey-light-50">{message?.dataset}</div>
							</div>
							<div>
								<div className="pb-[4px] font-medium">Data set last updated</div>
								<div className="text-uom-grey-light-50">{format(new Date(message?.dataset_modified_datetime ?? 0), 'LLL d, yyyy. h:mmbbb')}</div>
							</div>
							<div>
								<div className="pb-[4px] font-medium">Chunk size</div>
								<div className="text-uom-grey-light-50">{message?.query_configuration?.size}</div>
							</div>
							<div>
								<div className="pb-[4px] font-medium">Chunk type</div>
								<div className="text-uom-grey-light-50">token</div>
							</div>
							<div>
								<div className="pb-[4px] font-medium">RAG retrieval count</div>
								<div className="text-uom-grey-light-50">{message?.query_configuration?.size}</div>
							</div>
						</>
					)}
					<div>
						<div className="pb-[4px] font-medium">Large Language Model (LLM)</div>
						<div className="text-uom-grey-light-50">{message?.llm_configuration.model_name}</div>
					</div>
					<div className={clsx(isPlayground && 'min-[726px]:col-span-2')}>
						<div className="pb-[4px] font-medium">Temperature</div>
						<div className="text-uom-grey-light-50">{message?.llm_configuration.temperature}</div>
					</div>
					<div>
						<div className="pb-[4px] font-medium">Input token</div>
						<div className="text-uom-grey-light-50">{message?.tokens?.prompt_tokens}</div>
					</div>
					<div>
						<div className="pb-[4px] font-medium">Output token</div>
						<div className="text-uom-grey-light-50">{message?.tokens?.completion_tokens}</div>
					</div>
					<div>
						<div className="pb-[4px] font-medium">Total token</div>
						<div className="text-uom-grey-light-50">{message?.tokens?.total_tokens}</div>
					</div>
				</div>
				<div className="mt-[48px] max-w-content">
					<h3 className="text-lg font-medium leading-6">Custom instructions</h3>
					<div className="mt-1 text-sm font-medium">What would you like the AI model to know to provide better responses?</div>
					<textarea
						disabled
						className="mt-[4px] w-full rounded-[6px] border-uom-grey-light-100 bg-uom-heritage-150 text-sm font-normal leading-5 text-uom-grey-light-50"
						rows={6}
						value={message?.prompt_templates?.default.prompt_ai_to_know ?? message?.prompt_ai_to_know}
					/>
				</div>
			</div>
		</Draw>
	)
}
