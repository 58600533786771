import {Dialog, Transition} from '@headlessui/react'
import {Fragment, type PropsWithChildren} from 'react'

interface Props {
	open: boolean
	onCancel?: () => void
	onDone?: () => void
	onApply?: () => void
	applyLabel?: string
	applyDisabled?: boolean
}

export const Draw = ({open, onCancel, onDone, onApply, applyLabel, applyDisabled, children}: PropsWithChildren<Props>) => {
	return (
		<Transition.Root
			show={open}
			as={Fragment}
			appear={true}
		>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={() => {
					setTimeout(() => {
						if (onCancel != null) {
							onCancel()
						} else if (onDone != null) {
							onDone()
						}
					}, 200)
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed top-[60px] flex">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500"
								enterFrom="translate-y-full"
								enterTo="translate-y-0"
								leave="transform transition ease-in-out duration-500"
								leaveFrom="translate-y-0"
								leaveTo="translate-y-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen">
									<div className="flex h-[calc(100vh-60px)] w-full flex-col overflow-hidden rounded-t-[20px] bg-uom-heritage-150">
										<div className="flex w-full items-center justify-end gap-x-[8px] border-b border-uom-blue-light-100 bg-uom-heritage-100 px-[120px] py-[20px] text-xs font-medium leading-4">
											{onCancel != null && (
												<button
													type="button"
													className="rounded-[4px] border border-white px-[11px] py-[7px] text-white hover:text-gray-200"
													onClick={onCancel}
												>
													Cancel
												</button>
											)}
											{onDone != null && (
												<button
													type="button"
													className="rounded-[4px] border border-uom-grey-light-100 bg-white px-[11px] py-[7px] text-gray-700 hover:bg-gray-200"
													onClick={onDone}
												>
													Done
												</button>
											)}
											{onApply != null && (
												<button
													type="button"
													className="rounded-[4px] border border-uom-grey-light-100 bg-white px-[11px] py-[7px] text-gray-700 hover:bg-gray-200 disabled:cursor-not-allowed disabled:border-[#4a505d] disabled:bg-[#585d67] disabled:text-[#1c2434]"
													onClick={onApply}
													disabled={applyDisabled}
												>
													{applyLabel?.length ? applyLabel : 'Apply'}
												</button>
											)}
										</div>
										{children}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
