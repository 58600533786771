import {Menu} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid'
import {clsx} from 'clsx'
import {useEffect, useRef, useState} from 'react'
import {SparkIcon} from '~/assets/SparkIcon.tsx'
import {AttachedFilesInfo} from '~/components/AttachedFilesInfo.tsx'
import {MessageInfo} from '~/components/MessageInfo.tsx'
import {Attachment, type UIMessage} from '~/model.ts'
import {MessageRagSources} from '~/pages/project/MessageRagSources.tsx'
import {useUserInitials} from '~/services/auth.ts'
import {convertBytesNumberToString, isValidHttpUrl} from '~/util.ts'

interface MessagesProps {
	messages: UIMessage[]
	showSources?: boolean
	isPlayground?: boolean
	pendingMessage: string | null
}

export const Messages = ({messages, showSources, isPlayground, pendingMessage}: MessagesProps) => {
	const userInitials = useUserInitials()

	const [showMessageInfo, setShowMessageInfo] = useState<UIMessage | null>(null)
	const [showMessageRagSources, setShowMessageRagSources] = useState<UIMessage | null>(null)

	const [showAttachments, setShowAttachments] = useState<Attachment[] | null>(null)
	const [showAttachmentIndex, setShowAttachmentIndex] = useState<number | null>(null)

	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const pendingResponseRef = useRef<HTMLDivElement>(null)
	const endOfMessagesRef = useRef<HTMLDivElement>(null)

	// These two are separate because we want to scroll to the bottom of the thread instant when the messages change, but we want to scroll to the pending message when it changes
	useEffect(() => {
		scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight)
	}, [scrollContainerRef, messages])

	useEffect(() => {
		pendingResponseRef.current?.scrollIntoView({behavior: 'smooth'})
		endOfMessagesRef.current?.scrollIntoView({behavior: 'smooth'})
	}, [pendingMessage])

	return (
		<>
			<div
				ref={scrollContainerRef}
				className="h-full overflow-y-auto px-3"
			>
				<div className="mx-auto flex w-full max-w-[724px] flex-col gap-y-[24px] py-[24px] text-sm leading-5">
					{messages.map((message, index) => (
						<div
							key={index}
							className="group flex gap-x-[24px]"
						>
							<div
								className={clsx(
									message.role === 'user' ? 'bg-uom-blue-light-100' : 'bg-uom-yellow-100',
									'flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full text-uom-heritage-100',
								)}
							>
								{message.role === 'user' ? userInitials : <SparkIcon className="h-[22px]" />}
							</div>
							<div className="">
								{message.attachments != null && message.attachments.length > 0 && (
									<div className="mb-[14px] flex flex-wrap gap-[4px]">
										{message.attachments.map((attachment, index) => {
											const nameSplit = attachment.file_name.split('.')
											return (
												<button
													key={index}
													onClick={() => {
														setShowAttachments(message.attachments ?? null)
														setShowAttachmentIndex(index)
													}}
													className={clsx('flex items-center gap-x-[4px] rounded-[4px] bg-uom-heritage-75 px-[8px] py-[2px] text-[14px]')}
												>
													<div className="font-[400] leading-normal">
														{/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
														{attachment.file_name.length < 40 ? attachment.file_name : `${nameSplit[0]?.slice(0, 3)}...${nameSplit[0]?.slice(-3)}.${nameSplit[1]}`} //
													</div>
													<div className="font-[600] leading-[140%]">{convertBytesNumberToString(attachment.file_size)}</div>
												</button>
											)
										})}
									</div>
								)}
								<div className="whitespace-pre-line break-words rounded-[8px] bg-uom-heritage-100 p-[8px]">{message.converted_content ?? message.content}</div>

								{showSources && message.sources?.length != null && message.sources.length > 0 && (
									<div className="mt-[12px] space-y-[24px] rounded-[8px] bg-uom-heritage-100 p-[8px]">
										{message.sources.map((source) => (
											<div
												key={source.uri}
												className="flex flex-col gap-y-[8px]"
											>
												<div className="break-all text-sm font-medium leading-5">{source.title}</div>
												<div className="flex items-start gap-x-[8px] text-xs font-medium leading-4">
													<div className="font-semibold">Source:</div>{' '}
													{isValidHttpUrl(source.source) ? (
														<a
															href={source.source}
															className="break-all underline"
														>
															{source.source}
														</a>
													) : (
														<div className="break-all">{source.source}</div>
													)}
												</div>
												<div className="flex items-start gap-x-[20px] rounded-[4px] bg-uom-yellow-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-uom-heritage-100">
													<div>URI:</div>
													{isValidHttpUrl(source.uri) ? (
														<a
															href={source.uri}
															className="break-all underline"
														>
															{source.uri}
														</a>
													) : (
														<div className="break-all">{source.uri}</div>
													)}
												</div>
											</div>
										))}
									</div>
								)}
							</div>
							<div className="ml-auto">
								{message.role === 'assistant' && (
									<Menu
										as="div"
										className="relative z-[999]"
									>
										<Menu.Button className="invisible flex items-center justify-center rounded-full p-[2px] hover:hover:bg-uom-heritage-100 group-hover:visible">
											<EllipsisVerticalIcon className="h-[20px]" />
										</Menu.Button>
										<Menu.Items className="absolute right-0 top-[20px] mt-2 w-[200px] rounded-[6px] bg-uom-heritage-75 py-1 text-sm font-normal leading-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
											<Menu.Item>
												<button
													onClick={() => {
														setShowMessageInfo(message)
													}}
													className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
												>
													Message information
												</button>
											</Menu.Item>
											{!isPlayground && (
												<Menu.Item>
													<button
														onClick={() => {
															setShowMessageRagSources(message)
														}}
														className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
													>
														RAG sources
													</button>
												</Menu.Item>
											)}
										</Menu.Items>
									</Menu>
								)}
							</div>
						</div>
					))}
					{pendingMessage != null && (
						<>
							<div className="group flex gap-x-[24px]">
								<div className="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-uom-blue-light-100">{userInitials}</div>
								<div className="mt-[5px] whitespace-pre-line break-words bg-uom-heritage-100 p-[8px]">{pendingMessage}</div>
							</div>
							<div
								ref={pendingResponseRef}
								className="group flex gap-x-[24px]"
							>
								<div className="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-uom-yellow-100">
									<SparkIcon className="h-[22px]" />
								</div>
								<div className="relative flex items-center justify-center pl-[15px] pt-[5px]">
									<div className="dot-flashing"></div>
								</div>
							</div>
						</>
					)}
				</div>
				<div ref={endOfMessagesRef} />
			</div>

			<MessageInfo
				message={showMessageInfo ?? undefined}
				onDone={() => {
					setShowMessageInfo(null)
				}}
			/>

			<MessageRagSources
				message={showMessageRagSources ?? undefined}
				onDone={() => {
					setShowMessageRagSources(null)
				}}
			/>

			<AttachedFilesInfo
				attachments={showAttachments}
				showAttachmentIndex={showAttachmentIndex}
				onDone={() => {
					setShowAttachments(null)
				}}
			/>
		</>
	)
}
