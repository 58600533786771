import {Draw} from '~/components/Draw.tsx'
import {type Project} from '~/model.ts'
import {useDataSet} from '~/services/api.ts'
import {formatDate} from '~/util.ts'

interface Props {
	project?: Project
	onDone: () => void
	onShowConfig?: () => void
}

export const ProjectInfo = ({project, onDone, onShowConfig}: Props) => {
	const {data: dataSet} = useDataSet(project?.dataset)

	return (
		<Draw
			open={project != null}
			onDone={onDone}
		>
			<div className="overflow-y-auto px-[120px] py-[24px] text-sm font-normal leading-5 text-white">
				<div className="max-w-content">
					<h2 className="font-lead text-2xl font-medium leading-8">Project information</h2>
					<p className="mt-1 text-base font-normal leading-6">
						These details showcase the current configuration and token usage of this specific project.{' '}
						{project?.role !== 'VIEWER' && (
							<>
								Edit these values in{' '}
								<button
									className="underline"
									onClick={onShowConfig}
								>
									project configuration
								</button>
								.
							</>
						)}
					</p>
				</div>

				<h3 className="mt-[48px] text-lg font-medium leading-6">Name</h3>
				<p className="mt-[8px] text-uom-grey-light-50">{project?.name}</p>

				<h3 className="mt-[24px] text-lg font-medium leading-6">Description</h3>
				{project?.description != null && project.description.length > 0 ? (
					<p className="mt-[8px] text-uom-grey-light-50">{project.description}</p>
				) : (
					<p className="mt-[8px] text-gray-500">(No description provided)</p>
				)}

				<div className="mt-[48px] grid w-full max-w-content grid-cols-3 gap-[24px] rounded-[8px] border border-gray-400 p-[24px]">
					<div>
						<div className="pb-[4px] font-medium">Data set</div>
						<div className="break-words text-uom-grey-light-50">{dataSet?.name}</div>
					</div>
					<div>
						<div className="pb-[4px] font-medium">Dataset last update</div>
						<div className="text-uom-grey-light-50">{dataSet != null && formatDate(dataSet.modified_datetime)}</div>
					</div>
					<div>
						<div className="pb-[4px] font-medium">Chunk size</div>
						<div className="text-uom-grey-light-50">{dataSet?.vector_configuration.chunk_size}</div>
					</div>
				</div>

				<div className="mt-[48px] max-w-content">
					<h3 className="text-lg font-medium leading-6">Custom instructions</h3>
					<div className="mt-1 text-sm font-medium">What would you like the AI model to know to provide better responses?</div>
					<textarea
						disabled
						className="mt-[4px] w-full rounded-[6px] bg-uom-heritage-150 text-sm font-normal leading-5 text-gray-400"
						rows={6}
						value={project?.prompt_templates.default.prompt_ai_to_know}
					/>
				</div>
			</div>
		</Draw>
	)
}
