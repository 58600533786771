import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {EllipsisVerticalIcon, PlusIcon} from '@heroicons/react/24/solid'
import {useMemo, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {DeleteConfirmationModal} from '~/components/DeleteConfirmationModal.tsx'
import {LoadingAnimation} from '~/components/LoadingAnimation.tsx'
import {type NewProject, type Project} from '~/model.ts'
import {ProjectConfig} from '~/pages/project/ProjectConfig.tsx'
import {ProjectCreate} from '~/pages/project/ProjectCreate.tsx'
import {ProjectInfo} from '~/pages/project/ProjectInfo.tsx'
import {useCreateProject, useDataSets, useDeleteProject, useProjects, useUpdateProject} from '~/services/api.ts'
import {capitalizeFirstChar, formatDate} from '~/util.ts'

export const Projects = () => {
	const navigate = useNavigate()

	const {data: projects, isLoading: projectsIsLoading} = useProjects()
	const {data: dataSets} = useDataSets()
	const {mutateAsync: createProjectApi, isPending: createProjectIsPending} = useCreateProject()
	const {mutate: deleteProjectApi} = useDeleteProject()
	const {mutate: updateProjectApi} = useUpdateProject()

	const dataSetsCombined = dataSets != null ? [...dataSets.personalDataSets, ...dataSets.globalDataSets] : []

	const [createProjectOpen, setCreateProjectOpen] = useState(false)
	const [projectConfig, setProjectConfig] = useState<Project | null>(null)
	const [projectInfo, setProjectInfo] = useState<Project | null>(null)
	const [deleteProject, setDeleteProject] = useState<Project | null>(null)

	const projectsSortedByModifiedDatetime = useMemo(() => projects?.slice().sort((a, b) => (a.modified_datetime > b.modified_datetime ? -1 : 1)), [projects])

	function creatProject(newProject: NewProject) {
		setCreateProjectOpen(false)
		void createProjectApi(newProject).then((response) => {
			navigate(`/projects/${response.SK}`)
		})
	}

	if (createProjectIsPending) {
		return (
			<div className="flex items-center justify-center">
				<LoadingAnimation />
			</div>
		)
	}

	return (
		<>
			<div className="h-full overflow-y-auto px-[120px] py-[24px]">
				<h1 className="font-lead text-2xl font-medium leading-8">Projects</h1>
				<p className="mt-[8px] max-w-content text-base font-normal leading-6">
					Projects enable you to harness the capabilities of Large Language Models (LLM) while working with highly specific data sets.
				</p>
				{projectsIsLoading ? (
					<div className="flex items-center justify-center">
						<LoadingAnimation />
					</div>
				) : (
					<div className="mt-[48px] flex flex-wrap gap-[24px]">
						{projectsSortedByModifiedDatetime?.map((project) => (
							<Link
								key={project.name}
								to={`/projects/${project.SK}?latest`}
								className="w-[384px] rounded-[8px] border border-uom-heritage-50 bg-uom-heritage-100 p-[24px] hover:border-white focus:border-uom-yellow-100 focus:outline-none"
							>
								<div className="flex items-center justify-between text-base font-medium leading-6">
									<div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{project.name}</div>
									<Menu
										as="div"
										className="relative"
									>
										<MenuButton className="mx-[4px] flex items-center justify-center rounded-full border border-transparent p-[2px] hover:bg-uom-heritage-150 focus:border-uom-yellow-100 focus:outline-none data-[open]:bg-uom-heritage-150">
											<EllipsisVerticalIcon className="h-[20px]" />
										</MenuButton>
										<MenuItems className="absolute left-[2px] top-[20px] z-10 mt-2 w-[200px] rounded-[6px] bg-uom-heritage-75 py-1 text-sm font-normal leading-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
											{project.role !== 'VIEWER' && (
												<>
													<MenuItem>
														<button
															onClick={(event) => {
																setProjectConfig(project)
																event.preventDefault()
															}}
															className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
														>
															Rename project
														</button>
													</MenuItem>
													<MenuItem>
														<button
															onClick={(event) => {
																setProjectConfig(project)
																event.preventDefault()
															}}
															className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
														>
															Project configuration
														</button>
													</MenuItem>
												</>
											)}
											<MenuItem>
												<button
													onClick={(event) => {
														setProjectInfo(project)
														event.preventDefault()
													}}
													className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
												>
													Project information
												</button>
											</MenuItem>
											{project.role === 'OWNER' && (
												<MenuItem>
													<button
														onClick={(event) => {
															setDeleteProject(project)
															event.preventDefault()
														}}
														className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
													>
														Delete project
													</button>
												</MenuItem>
											)}
										</MenuItems>
									</Menu>
								</div>
								<div className="mt-[8px] text-sm font-normal leading-5 text-uom-grey-light-50">Last update: {formatDate(project.modified_datetime)}</div>
								<div className="mt-[4px] overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-normal leading-5 text-uom-grey-light-50">
									Data set: {dataSetsCombined.find((dataset) => dataset.SK === project.dataset)?.name}
								</div>
								<div className="mt-[12px] flex items-center justify-start text-sm font-normal leading-5">
									Role:
									<div className="ml-[5px] w-max rounded-[4px] bg-uom-heritage-75 px-[8px] py-[2px]">{capitalizeFirstChar(project.role)}</div>
								</div>
							</Link>
						))}
						<button
							onClick={() => {
								setCreateProjectOpen(true)
							}}
							className="flex min-h-[125px] w-[384px] items-center justify-center gap-x-[8px] rounded-[8px] border border-dashed border-uom-heritage-50 bg-uom-heritage-100 p-[24px] hover:border-white focus:border-uom-yellow-100 focus:outline-none"
						>
							<PlusIcon className="h-[20px]" />
							<div>Add project</div>
						</button>
					</div>
				)}
			</div>

			<ProjectCreate
				open={createProjectOpen}
				onCancel={() => {
					setCreateProjectOpen(false)
				}}
				onCreateProject={creatProject}
			/>

			<ProjectConfig
				open={projectConfig != null}
				isPlayground={false}
				config={projectConfig ?? undefined}
				onCancel={() => {
					setProjectConfig(null)
				}}
				onApply={(projectConfig) => {
					updateProjectApi(projectConfig)
					setProjectConfig(null)
				}}
			/>

			<ProjectInfo
				project={projectInfo ?? undefined}
				onDone={() => {
					setProjectInfo(null)
				}}
				onShowConfig={() => {
					setProjectConfig(projectInfo)
					setProjectInfo(null)
				}}
			/>

			<DeleteConfirmationModal
				open={deleteProject != null}
				itemType="project"
				onClose={() => {
					setDeleteProject(null)
				}}
				onDelete={() => {
					if (deleteProject != null) {
						deleteProjectApi(deleteProject)
					}
					setDeleteProject(null)
				}}
			/>
		</>
	)
}
