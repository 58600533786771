import {ArrowRightIcon} from '@heroicons/react/16/solid'
import {useAtom} from 'jotai'
import {useEffect, useRef, useState} from 'react'
import {InfoCircleIcon} from '~/assets/InfoCircleIcon.tsx'
import {AttachFiles} from '~/components/AttachFiles.tsx'
import {useReferenceData} from '~/services/api.ts'
import {selectedFilesAtom, selectFilesStateAtom} from '~/services/state.ts'

interface PromptInputProps {
	sendNewMessage: (newMessage: string) => void
	modelName: string
}

export const PromptInput = ({sendNewMessage, modelName}: PromptInputProps) => {
	const [count, setCount] = useState(1) // This is a huge hack to make the textarea clear, new lines were persisting even after value was set to ''
	const [text, setText] = useState('')
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const {data: referenceData} = useReferenceData()
	const [selectFilesState] = useAtom(selectFilesStateAtom)
	const [, setSelectFilesState] = useAtom(selectedFilesAtom)

	const modelHasFileUpload = referenceData?.ai_models.find((model) => model.value === modelName)?.features.file_upload

	useEffect(() => {
		if (!modelHasFileUpload) {
			setSelectFilesState({})
		}
	}, [modelHasFileUpload])

	const isPlayground = window.location.pathname.startsWith('/securechat')

	const disableSendButton = text.length === 0 || selectFilesState.someNotProcessed
	const showPromptRequired = selectFilesState.isFiles && !selectFilesState.someNotProcessed && text.length === 0

	useEffect(() => {
		const textArea = textareaRef.current
		if (textArea) {
			textArea.style.height = 'auto' // Reset height to get the correct scrollHeight
			if (text === '' || textArea.scrollHeight < 72) {
				// When there should only be one row
				textArea.style.height = '24px'
			} else {
				textArea.style.height = `${Math.min(textArea.scrollHeight, 260)}px`
			}
		}
	}, [text])

	const send = () => {
		if (disableSendButton) return
		sendNewMessage(text)
		setText('')
		setCount(count + 1)
		textareaRef.current?.focus()
	}

	return (
		<div className="mx-auto w-full max-w-content-sm">
			{showPromptRequired && (
				<div className="mb-[6px] flex items-center rounded-[2px] bg-uom-blue-light-25 px-[8px] py-[2px] text-uom-heritage-100">
					<InfoCircleIcon className="mr-[4px] size-[16px] text-uom-heritage-100" />
					Type a message prior to sending files
				</div>
			)}
			<div className="flex items-end overflow-hidden rounded-[6px] border border-gray-400 bg-uom-heritage-100 p-[12px] ring-1 ring-transparent focus-within:border-uom-yellow-100 focus-within:ring-uom-yellow-100">
				<div className="flex flex-grow flex-col justify-center">
					<textarea
						key={count}
						ref={textareaRef}
						rows={1}
						className="h-full w-full resize-none overflow-y-auto !border-0 bg-uom-heritage-100 p-0 text-uom-grey-light-50 !ring-0"
						value={text}
						placeholder="Ask a question..."
						onChange={(e) => {
							setText(e.target.value)
						}}
						onKeyDown={(event) => {
							if (event.key === 'Enter' && !event.shiftKey) send()
						}}
					/>
					{isPlayground && modelHasFileUpload && <AttachFiles />}
				</div>
				<div className="flex shrink-0">
					<button
						className="foucs:outline-none rounded-[4px] bg-uom-blue-light-100 p-[4px] text-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-uom-blue-light-50 disabled:bg-uom-heritage-75 disabled:text-white"
						onClick={send}
						disabled={disableSendButton}
					>
						<ArrowRightIcon
							className="h-[16px]"
							aria-hidden="true"
						/>
					</button>
				</div>
			</div>
		</div>
	)
}
