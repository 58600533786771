import {Menu} from '@headlessui/react'
import {EllipsisVerticalIcon, LockClosedIcon, PlusIcon} from '@heroicons/react/24/solid'
import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {DeleteConfirmationModal} from '~/components/DeleteConfirmationModal.tsx'
import {ErrorModal} from '~/components/ErrorModal.tsx'
import {LoadingAnimation} from '~/components/LoadingAnimation.tsx'
import {type DataSet} from '~/model.ts'
import {DataSetCreate} from '~/pages/dataSets/DataSetCreate.tsx'
import {GlobalDataSetInfo} from '~/pages/dataSets/GlobalDataSetInfo.tsx'
import {useCreateDataSet, useDataSets, useDeleteDataSet, useProjects} from '~/services/api.ts'
import {capitalizeFirstChar, formatDate} from '~/util.ts'

export const DataSets = () => {
	const navigate = useNavigate()

	const {data: dataSets, isLoading} = useDataSets()
	const {data: projects} = useProjects()
	const {mutateAsync: createDataSet, isPending: createDataSetIsPending} = useCreateDataSet()
	const {mutate: deleteDataSetApi} = useDeleteDataSet()

	const [createDataSetOpen, setCreateDataSetOpen] = useState(false)
	const [deleteDataSet, setDeleteDataSet] = useState<DataSet | null>(null)
	const [viewGlobalDataSet, setViewGlobalDataSet] = useState<DataSet | null>(null)
	const [cantDeleteDataSet, setCantDeleteDataSet] = useState(false)

	const onCreateDataSet = (dataSet: DataSet) => {
		setCreateDataSetOpen(false)
		void createDataSet(dataSet).then((response) => {
			navigate(`/datasets/${response.SK}/files`)
		})
	}

	const onDeleteDataSet = (dataSet: DataSet) => {
		if (projects == null) return
		const projectsUsingDataSet = projects.filter((project) => project.dataset === dataSet.SK)
		if (projectsUsingDataSet.length > 0) {
			setCantDeleteDataSet(true)
		}
		setDeleteDataSet(dataSet)
	}

	if (createDataSetIsPending) {
		return (
			<div className="flex items-center justify-center">
				<LoadingAnimation />
			</div>
		)
	}

	return (
		<>
			<div className="overflow-y-auto px-[120px] py-[24px]">
				<h1 className="font-lead text-2xl font-medium leading-8">Data sets</h1>
				<p className="mt-[8px] max-w-content text-base font-normal leading-6">
					Data sets are curated information pools. When creating a project, choose either a global or custom data set. The AI model in your project will use the selected data set as a reference.
				</p>

				{isLoading ? (
					<div className="flex items-center justify-center">
						<LoadingAnimation />
					</div>
				) : (
					<>
						<h2 className="mt-[48px] text-lg font-medium leading-6">Custom data sets</h2>
						<p className="mt-[8px]">Only you have access to the custom data sets that you create.</p>
						<div className="mt-[24px] flex flex-wrap gap-[24px]">
							{dataSets?.personalDataSets.map((dataSet) => (
								<Link
									key={dataSet.name}
									to={`/datasets/${dataSet.SK}`}
									className="w-[384px] rounded-[8px] border border-uom-heritage-50 bg-uom-heritage-100 p-[24px] hover:border-white focus:border-uom-yellow-100 focus:outline-none"
								>
									<div className="flex items-center justify-between text-base font-medium leading-6">
										<div className="flex-1 overflow-hidden overflow-ellipsis">{dataSet.name}</div>
										{dataSet.role !== 'VIEWER' && (
											<Menu
												as="div"
												className="relative"
											>
												<Menu.Button className="mx-[4px] flex items-center justify-center rounded-full border border-transparent p-[2px] hover:bg-uom-heritage-150 focus:border-uom-yellow-100 focus:outline-none data-[open]:bg-uom-heritage-150">
													<EllipsisVerticalIcon className="h-[20px]" />
												</Menu.Button>
												<Menu.Items className="absolute left-[2px] top-[20px] z-10 mt-2 w-[200px] rounded-[6px] bg-uom-heritage-75 py-1 text-sm font-normal leading-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
													<Menu.Item>
														<button
															onClick={(event) => {
																event.preventDefault()
																navigate(`/datasets/${dataSet.SK}?rename`)
															}}
															className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
														>
															Rename data set
														</button>
													</Menu.Item>
													{dataSet.role === 'OWNER' && (
														<Menu.Item>
															<button
																onClick={(event) => {
																	onDeleteDataSet(dataSet)
																	event.preventDefault()
																}}
																className="block w-full px-4 py-2 text-left data-[active]:bg-uom-heritage-100"
															>
																Delete data set
															</button>
														</Menu.Item>
													)}
												</Menu.Items>
											</Menu>
										)}
									</div>
									<div className="mt-[8px] text-sm font-normal leading-5">Last updated: {formatDate(dataSet.modified_datetime)}</div>
									<div className="mt-[12px] flex items-center justify-start text-sm font-normal leading-5">
										Role:
										<div className="ml-[5px] w-max rounded-[4px] bg-uom-heritage-75 px-[8px] py-[2px]">{capitalizeFirstChar(dataSet.role)}</div>
									</div>
								</Link>
							))}
							<button
								onClick={() => {
									setCreateDataSetOpen(true)
								}}
								className="flex min-h-[100px] w-[384px] items-center justify-center gap-x-[8px] rounded-[8px] border border-dashed border-uom-heritage-50 bg-uom-heritage-100 p-[24px] hover:border-white focus:border-uom-yellow-100 focus:outline-none"
							>
								<PlusIcon className="h-[20px]" />
								<div>Add data set</div>
							</button>
						</div>
						<h2 className="mt-[48px] text-lg font-medium leading-6">Global data sets</h2>
						<p className="mt-[8px]">Global data sets are available to all UniMelb staff. At this stage, you cannot add or edit global data sets.</p>
						<div className="mt-[24px] flex flex-wrap gap-[24px]">
							{dataSets?.globalDataSets.map((dataSet) => (
								<button
									key={dataSet.SK}
									className="w-[384px] rounded-[8px] border border-uom-heritage-50 bg-uom-heritage-100 p-[24px] text-left hover:border-white focus:border-uom-yellow-100 focus:outline-none"
									onClick={() => {
										setViewGlobalDataSet(dataSet)
									}}
								>
									<div className="flex items-center gap-x-[8px] text-base font-medium leading-6">
										<LockClosedIcon className="h-[16px] text-uom-yellow-100" />
										<div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{dataSet.name}</div>
									</div>
									<div className="mt-[8px] text-sm font-normal leading-5">Last updated: {formatDate(dataSet.modified_datetime)}</div>
								</button>
							))}
						</div>
					</>
				)}
			</div>

			<DataSetCreate
				open={createDataSetOpen}
				onCancel={() => {
					setCreateDataSetOpen(false)
				}}
				onCreateDataSet={onCreateDataSet}
			/>

			<DeleteConfirmationModal
				open={deleteDataSet != null}
				itemType="data set"
				onClose={() => {
					setDeleteDataSet(null)
				}}
				onDelete={() => {
					if (deleteDataSet != null) {
						deleteDataSetApi(deleteDataSet)
					}
					setDeleteDataSet(null)
				}}
			/>

			<GlobalDataSetInfo
				dataSet={viewGlobalDataSet ?? undefined}
				onDone={() => {
					setViewGlobalDataSet(null)
				}}
			/>

			<ErrorModal
				title="You can’t delete this data set"
				open={cantDeleteDataSet}
				onClose={() => {
					setCantDeleteDataSet(false)
					setDeleteDataSet(null)
				}}
			>
				<p>This data set is linked to at least one project.</p>
				<p className="mt-[1rem]">You can only delete data sets that are not linked to any projects.</p>
			</ErrorModal>
		</>
	)
}
