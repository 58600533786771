import {QueryClientProvider} from '@tanstack/react-query'
import {StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import {AuthProvider} from 'react-oidc-context'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {DataSetDetails} from '~/pages/dataSets/DataSetDetails.tsx'
import {DataSetFiles} from '~/pages/dataSets/DataSetFiles.tsx'
import {DataSets} from '~/pages/dataSets/DataSets.tsx'
import {DataSetView} from '~/pages/dataSets/DataSetView.tsx'
import {Projects} from '~/pages/project/Projects.tsx'
import {ProjectView} from '~/pages/project/ProjectView.tsx'
import {Settings} from '~/pages/settings/Settings.tsx'
import {Root} from '~/Root.tsx'
import {queryClient} from '~/services/api.ts'
import './index.css'
import {LoginCallback, oidcConfig} from '~/services/auth.ts'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		children: [
			{
				path: '/securechat/threads?/:threadId?',
				element: <ProjectView isPlayground={true} />,
			},
			{
				path: '/projects',
				element: <Projects />,
			},
			{
				path: '/projects/:projectId/threads?/:threadId?',
				element: <ProjectView />,
			},
			{
				path: '/datasets',
				element: <DataSets />,
			},
			{
				path: '/datasets/:dataSetId',
				element: <DataSetView />,

				children: [
					{
						path: '/datasets/:dataSetId',
						element: <DataSetDetails />,
					},
					{
						path: 'files',
						element: <DataSetFiles />,
						children: [],
					},
				],
			},
			{
				path: '/settings',
				element: <Settings />,
			},
		],
	},
	{
		path: '/login/callback',
		element: <LoginCallback />,
	},
])

// Set dark mode early, so we don't get a flash of light mode
const darkMode = localStorage.getItem('darkMode')
if (darkMode === 'true' || darkMode == null) {
	document.documentElement.classList.add('dark')
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<AuthProvider {...oidcConfig}>
				<RouterProvider router={router} />
			</AuthProvider>
		</QueryClientProvider>
	</StrictMode>,
)
