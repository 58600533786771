import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react'
import {type PropsWithChildren, useRef} from 'react'
import {InfoIcon} from '~/assets/InfoIcon.tsx'

interface InfoModalProps {
	heading: string
	open: boolean
	close: () => void
}

export function InfoModal({heading, children, open, close}: PropsWithChildren<InfoModalProps>) {
	const okButtonRef = useRef(null)

	return (
		<Dialog
			open={open}
			onClose={close}
			className="relative z-10"
			initialFocus={okButtonRef}
		>
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
			/>

			<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<DialogPanel
						transition
						className="relative m-8 w-full max-w-[512px] transform overflow-hidden rounded-lg bg-uom-heritage-100 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
					>
						<div className="px-[24px] pb-[16px] pt-[24px] sm:flex sm:items-start">
							<div className="mx-auto flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-full bg-uom-heritage-75 sm:mx-0">
								<InfoIcon
									className="h-[24px] text-white"
									aria-hidden="true"
								/>
							</div>
							<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
								<DialogTitle
									as="h3"
									className="text-lg font-medium leading-6 text-white"
								>
									{heading}
								</DialogTitle>
								<div className="mt-[8px] text-sm font-normal leading-5 text-uom-grey-light-50">{children}</div>
							</div>
						</div>
						<div className="bg-uom-heritage-75 px-[24px] py-[12px] text-sm font-medium leading-5 sm:flex sm:flex-row-reverse">
							<button
								ref={okButtonRef}
								type="button"
								className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-uom-grey-dark-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
								onClick={() => {
									close()
								}}
							>
								Okay
							</button>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	)
}
